import styles from "./styles.module.scss"
import { ReactComponent as LeftIcon } from "../../../assets/images/datePicker/leftIcon.svg"
import { ReactComponent as RightIcon } from "../../../assets/images/datePicker/rightIcon.svg"
import { useRef } from "react"

export default function CustomSlider({ data, name, value, setValue, min, max }) {
  const ref = useRef()

  return (
    <div className={`${styles.container} ${name === 'days' && styles.days} center`}>
      <button
        className={`${styles.btn} center`}
        onClick={() => {
          value !== min && setValue(value - 1);
          const cont = ref.current;
          cont.scrollLeft = name === 'days' ? 40*(value > 15 ? value : value-2.5) : (100*(value-2.5));
        }}
      >
        <LeftIcon height={14} width={14} />
      </button>
      <div className={`${styles.centralContainer}`} ref={ref}>
        {data?.map((el, ind) => (
          <div
            onClick={() => setValue(ind+1)}
            key={el}
            className={`${styles.monthEle} ${value === (ind+1) && styles.active}`}
          >
            {el}
          </div>
        ))}
      </div>
      <button
        className={`${styles.btnR} center`}
        onClick={() => {
            value !== max && setValue(value + 1);
            const cont = ref.current;
           const scrollAmount = name === 'days' ? 40*(value < 15 ? value : value+3) : (100*(value));
           cont.scrollLeft =scrollAmount;
        }
        }
      >
        <RightIcon height={14} width={14} />
      </button>
    </div>
  )
}
