import { all, call, fork, takeEvery } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, FORGET_PASSWORD_RESET } from "./actionTypes"

//Include Both Helper File with needed methods
import { toast } from "react-toastify"
import {
  forgotPassword,
  resetPassword,
} from "../../../helpers/fakebackend_helper"
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"

// const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user } }) {
  try {
    const response = yield call(resetPassword, user)
    toast.success(response?.message, {
      autoClose: 1000,
    })
    // yield put(userForgetPasswordSuccess(response))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Something went wrong"
    console.log("error", error)
    toast.error(errorMessage, { autoClose: 3000 })
    // yield put(userForgetPasswordError(error))
  }
}

function* forgetUserPasswordReset({ payload: { data } }) {
  try {
    const response = yield call(forgotPassword, data)
    toast.success(response?.message, {
      autoClose: 1000,
    })
    window.location.href = "/login"
    // yield put(userForgetPasswordSuccess(response))
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Something went wrong"
    console.log("error", error)
    toast.error(errorMessage, { autoClose: 3000 })
    // yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(FORGET_PASSWORD_RESET, forgetUserPasswordReset)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
