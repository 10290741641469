// Billing Date
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';

/* EVENTS */
export const GET_EVENTS = "GET_EVENTS"
export const GET_SINGLE_EVENTS_DETAILS = "GET_SINGLE_EVENTS_DETAILS"
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS"
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL"

export const ADD_NEW_EVENT = "ADD_NEW_EVENT"
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS"
export const ADD_EVENT_DETAIL = "ADD_EVENT_DETAIL"
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL"

export const UPDATE_EVENT = "UPDATE_EVENT"
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS"
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL"

export const DELETE_EVENT = "DELETE_EVENT"
export const LATEST_EVENT = "LATEST_EVENT"
export const LATEST_EVENT_DATA = "LATEST_EVENT_DATA"
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS"
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL"

export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

export const IS_LOADING = "IS_LOADINGa"
export const IS_EVENT_LOADING = "IS_EVENT_LOADING"

// Tenant
export const GET_TENANT_DETAILS = "GET_TENANT_DETAILS"
export const GET_TENANT_DETAILS_SUCCESS = "GET_TENANT_DETAILS_SUCCESS"
export const GET_TENANT_DETAILS_FAIL = "GET_TENANT_DETAILS_FAIL"

