import {
  GET_EVENTS,
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  ADD_NEW_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  IS_LOADING,
  GET_SINGLE_EVENTS_DETAILS,
  ADD_EVENT_DETAIL,
  LATEST_EVENT,
  LATEST_EVENT_DATA,
  IS_EVENT_LOADING,
  GET_TENANT_DETAILS,
  GET_TENANT_DETAILS_SUCCESS,
  GET_TENANT_DETAILS_FAIL,
} from "../billing/actionTypes"

export const getEvents = data => ({
  type: GET_EVENTS,
  payload: data,
})

export const getEventsSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
})

export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
})

export const addNewEvent = event => ({
  type: ADD_NEW_EVENT,
  payload: event,
})

export const addEventSuccess = event => ({
  type: ADD_EVENT_SUCCESS,
  payload: event,
})

export const addEventDetails = data => ({
  type: ADD_EVENT_DETAIL,
  payload: data.data,
})

export const addEventFail = error => ({
  type: ADD_EVENT_FAIL,
  payload: error,
})

export const updateEvent = event => ({
  type: UPDATE_EVENT,
  payload: event,
})

export const updateEventSuccess = event => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event,
})

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
})

export const deleteEvent = event => ({
  type: DELETE_EVENT,
  payload: event,
})

export const latestEventDetail = data => ({
  type: LATEST_EVENT,
  payload: data,
})

export const latestEventData = data => ({
  type: LATEST_EVENT_DATA,
  payload: data,
})

export const deleteEventSuccess = event => ({
  type: DELETE_EVENT_SUCCESS,
  payload: event,
})

export const deleteEventFail = error => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
})

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const isLoading = value => ({
  type: IS_LOADING,
  payload: value,
})

export const isEventLoading = value => ({
  type: IS_EVENT_LOADING,
  payload: value,
})



export const singleEventDetails = value => ({
  type: GET_SINGLE_EVENTS_DETAILS,
  payload: value,
})

export const getTenantDetails = ({tenantnum}) => ({
  type: GET_TENANT_DETAILS,
  tenantnum
})

export const getTenantDetailsSuccess = data => ({
  type: GET_TENANT_DETAILS_SUCCESS,
  payload: data,
})

export const getTenantDetailsFail = error => ({
  type: GET_TENANT_DETAILS_FAIL,
  payload: error,
})