// import Content from "components/Common/ContentContainer";
import { useEffect } from "react";
import Header from "./Header"
import Sidebar from "./Sidebar"
import styles from "./layout.module.scss";
import { useDispatch } from "react-redux";
import { getMetaData } from "store/actions";

export default function Layout({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetaData());
  }, [])

  return (
    <>
      <div className={styles.main_container}>
        <Header />
        <Sidebar />
        {/* <Content /> */}
        {children}
        {/* <div className={styles.content_container}>
          
          <div className="page-content">
            <div style={{ position: "relative" }}>
              <div className={styles.page_header}> </div>
              <div className={styles.content_main_container}>
                <div className="content-container">{children}</div>
              </div>
            </div>
          </div>
        </div>  */}
      </div>
    </>
  )
}
