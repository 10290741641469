import React, { useEffect, useState } from "react"
import styles from "./dateRange.module.scss"
import CustomDatePicker from "./customDatePicker"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { SET_END_DATE, SET_START_DATE } from "store/filters/actionTypes";

const DateRangePicker = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(state => state.Filters);

  const [startDateLocal, setStartDateLocal] = useState(startDate)
  const [endDateLocal, setEndDateLocal] = useState(endDate)

  const apply = (start, date) => {
    dispatch( start ? {
      type: SET_START_DATE,
      payload: date
    } : {
      type: SET_END_DATE,
      payload: date
    })
    
  }

  const cancel = () => {
    setStartDateLocal(startDate)
    setEndDateLocal(endDate)
  }
  
  useEffect(()=> {
    cancel()
  }, [startDate, endDate])

  return (
    <div className={styles.container}>
      <CustomDatePicker
        label={"Start Month"}
        setDate={setStartDateLocal}
        date={startDateLocal}
        start={true}
        apply={apply}
        cancel={cancel}
      />
      <div className={styles.dateContainer}>
        <p>{moment(startDate).format("MMM YY")}</p>
        <p> - </p>
        <p>{moment(endDate).format("MMM YY")}</p>
      </div>
      <CustomDatePicker
        label={"End Month"}
        setDate={setEndDateLocal}
        date={endDateLocal}
        apply={apply}
        cancel={cancel}
      />
    </div>
  )
}

export default DateRangePicker
