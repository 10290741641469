import moment from "moment"

export const formatCurrency = (
  number,
  locale = "en-US",
  currency = "USD",
  deci = 2
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: deci,
    maximumFractionDigits: deci,
  }).format(number)
}

export const formatNumber = (number, locale = "en-US", deci = 2) => {
  if (number >= 1000 || number <= -1000) {
    const formattedNumber = (number / 1000).toFixed(deci)
    // Remove trailing zeros from decimal part if not needed
    const strippedNumber = parseFloat(formattedNumber).toString()
    return strippedNumber.toLocaleString() + "k"
  }

  if (Number.isFinite(number) && !Number.isInteger(number)) {
    return new Intl.NumberFormat(locale).format(number.toFixed(deci))
  } else {
    return new Intl.NumberFormat(locale).format(number)
  }
}

export const getDaysListInMonth = (year, month) => {
  const daysInMonth = moment([year, month]).daysInMonth()
  const daysList = []
  for (let day = 1; day <= daysInMonth; day++) {
    daysList.push(day)
  }
  return { daysList, daysInMonth }
}

export const calcAverage = (key, _data, noOfOccupant = false) => {
  const data = _data || []

  let avg = 0
  if (noOfOccupant) {
    avg = _.meanBy(data, key)
    avg = formatNumber(avg / noOfOccupant)
  } else {
    avg = _.meanBy(data, key)
    avg = formatNumber(avg)
  }
  let current = ""
  let last = ""
  if (data?.length > 0) {
    current = data[data?.length - 1]

    if (current) {
      last = data[data?.length - 2]

      if (last) {
        const currentRecord = current?.[key]
        const lastRecord = last?.[key]
        const percentageChange = (currentRecord / lastRecord - 1) * 100
        const roundedChange = Math.round(percentageChange)
        return { percentage: roundedChange, avg }
      } else {
        return { percentage: 100, avg }
      }
    } else {
      return { percentage: -100, avg }
    }
  }
}
export const calcSum = (key, _data, noOfOccupant = false) => {
  const data = _data || []

  let avg = 0
  if (noOfOccupant) {
    avg = _.sumBy(data, key)
    avg = formatNumber(avg / noOfOccupant, "en-US", 1)
  } else {
    avg = _.sumBy(data, key)
    avg = formatNumber(avg, "en-US", 1)
  }
  let current = ""
  let last = ""
  if (data?.length > 0) {
    current = data[data?.length - 1]

    if (current) {
      last = data[data?.length - 2]

      if (last) {
        const currentRecord = current?.[key]
        const lastRecord = last?.[key]
        const percentageChange = (currentRecord / lastRecord - 1) * 100
        const roundedChange = Math.round(percentageChange)
        return { percentage: roundedChange, avg }
      } else {
        return { percentage: 100, avg }
      }
    } else {
      return { percentage: -100, avg }
    }
  }
}

export const validateDateRange = (startDate, endDate) => {
  // Parse the input dates using Moment.js
  const startMoment = moment(startDate)
  const endMoment = moment(endDate)

  // Check if the end date is not smaller than the start date
  if (endMoment.isBefore(startMoment)) {
    return "Incorrect date range selection. The start date should always be earlier than the end date."
  }

  // Calculate the difference in months
  const monthDifference = endMoment.diff(startMoment, "months")

  // Check if the difference is greater than 12 months
  if (monthDifference > 12 || monthDifference < 1) {
    return "The difference between start and end dates must between 1 to 12 months"
  }

  return false
}

export const dateFormat = date => {
  return moment(date, '"YYYY-MM-DD"').format('"YYYY-MM-DD"')
}
