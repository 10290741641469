import moment from "moment"
import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_EVENTS,
  IS_LOADING,
  GET_SINGLE_EVENTS_DETAILS,
  ADD_EVENT_DETAIL,
  LATEST_EVENT,
  LATEST_EVENT_DATA,
  IS_EVENT_LOADING,
  GET_TENANT_DETAILS,
  GET_TENANT_DETAILS_SUCCESS,
  GET_TENANT_DETAILS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  events: [],
  categories: [],
  error: {},
  loading: false,
  eventLoading: false,
  singleEvent: {},
  latestEvent: [],
  singleEventDetail: {},
  tenantDetails: [],
  tenantLoading: false
}

const Billing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
      }

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.data,
      }

    case GET_EVENTS_FAIL:
      return {
        ...state,
        events: [],
        error: action.payload,
      }

    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload],
      }

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      }

    case UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case IS_LOADING:
      return {
        ...state,
        eventLoading: action.payload,
      }
    case IS_EVENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case GET_SINGLE_EVENTS_DETAILS:
      return {
        ...state,
        singleEvent: action.payload,
      }

    case ADD_EVENT_DETAIL:
      return {
        ...state,
        singleEventDetail: action.payload,
      }
    // case LATEST_EVENT: {
    //   return {
    //     ...state,
    //     latestEvent: action.payload,
    //   }
    // }
    case LATEST_EVENT_DATA: {
      return {
        ...state,
        latestEvent: action.payload,
      }
    }
    case GET_TENANT_DETAILS:
      return {
        ...state,
        tenantDetails: {},
        tenantLoading: true,
      }

    case GET_TENANT_DETAILS_SUCCESS:
      return {
        ...state,
        tenantDetails: action.payload,
        tenantLoading: false
      }

    case GET_TENANT_DETAILS_FAIL:
      return {
        ...state,
        tenantDetails: {},
        tenantLoading: false
      }
    default:
      return state
  }
}

export default Billing;
