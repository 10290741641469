import {
  EDIT_PROFILE,
  EDIT_PROFILE_FLAG,
  GET_PROFILE,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

const initialState = {
  profile: {},
  error: "",
  success: false,
  resetSuccess: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = { ...state, profile: action.payload.user }
      break
    case EDIT_PROFILE:
      state = {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.user,
        },
      }
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.user,
        },
      }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case EDIT_PROFILE_FLAG:
      console.log("action.payload", action.payload)
      state = { ...state, success: action.payload.flag }
      break
    case RESET_PROFILE_FLAG:
      console.log("action.payload", action.payload)
      state = { ...state, resetSuccess: action.payload.flag }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
