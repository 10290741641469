import React, { forwardRef, useState } from "react"
import DatePicker from "react-datepicker"
import "./yearStyles.scss"
import styles from "./yearPicker.module.scss"
import { ReactComponent as DownIcon } from "../../../assets/images/datePicker/downIcon.svg"
import { ReactComponent as LeftIcon } from "../../../assets/images/datePicker/leftIcon.svg"
import { ReactComponent as RightIcon } from "../../../assets/images/datePicker/rightIcon.svg"

import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import { SET_YEAR } from "store/LDP/actionTypes"



const CustomYearPicker = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {selectedYear} = useSelector(state => state.LDP);
  const [year, setYear] = useState(selectedYear);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`${styles.inputBtn} ${open && styles.active}`}
      onClick={handleCalendarOpen}
      ref={ref}
    >
      {selectedYear.getFullYear()} <DownIcon />
    </button>
  ));

  const handleYearChange = (date) => {
    dispatch({
      type: SET_YEAR,
      payload: date
    })
  }

  const handleCalendarOpen = () => {
    !open && setYear(selectedYear);
    setOpen(!open);
  }

  return (
    <>
      <DatePicker
        selected={year}
        onChange={(date) => setYear(date)}
        // dateFormat="MM/yyyy"
        showYearPicker
        customInput={<CustomInput />}
        onClickOutside={() => setOpen(false)}
        popperPlacement="bottom-start"
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
          nexYearButtonDisabled,
        }) => (
          <div className={styles.dateHeader}>
            <button onClick={decreaseYear}>
              <LeftIcon height={17} width={17} />
            </button>
            <div className={styles.yearText}>{year.getFullYear()}</div>
            <button onClick={increaseYear} disabled={nexYearButtonDisabled}>
              <RightIcon height={17} width={17} />
            </button>
          </div>
        )}
        renderYearContent={(year) => {
          return <span className={styles.yearStyle}>{year}</span>
        }}
        onCalendarClose={() => setOpen(false)}
        onCalendarOpen={handleCalendarOpen}
        shouldCloseOnSelect={false}
        open={open}
      >
        <div className={styles.datePickerFooter}>
            <button
              onClick={() => {
                setYear(new Date())
                handleYearChange(new Date());
                setOpen(false)
              }}
            >
              Current Year
            </button>
            <button
              onClick={() => {
                setOpen(false);
                handleYearChange(year);
              }}
            >
              Apply
            </button>
        </div>
      </DatePicker>
    </>
  )
}

export default CustomYearPicker
