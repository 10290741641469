import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import cplLogo from "../../assets/images/cpl-logo-removebg.jpg"

const ForgetPasswordPage = props => {
  function handleValidSubmit(event, values) {
    props.userForgetPassword(values)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | GENERGY</title>
      </MetaTags>
      <div
        className="account-pages"
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0 px-0">
                  <div className=" py-1" style={{ backgroundColor: "#3668CC" }}>
                    <h4
                      className="pt-1 text-white font-size-15 font-italic mb-0 text-center fw-normal !important"
                      style={{ fontStyle: "italic" }}
                    >
                      Tenant
                    </h4>
                    <h2 className="text-white text-center fw-bold mb-0">
                      RESET PASSWORD
                    </h2>
                  </div>
                  <div className="p-3">
                    {/* <h4 className="text-muted font-size-18 mb-3 text-center">
                      Reset Password
                    </h4> */}
                    <div
                      className="alert alert-info mx-3"
                      role="alert"
                      style={{
                        backgroundColor: "#7FAED9",
                        color: "white",
                        border: "0",
                      }}
                    >
                      Enter your Email and instructions will be sent to you
                    </div>
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal mt-4 mx-3"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <div className=" mt-5 px-5 d-flex justify-content-center">
                        <button
                          className="btn btn-primary waves-effect waves-light rounded-pill  mx-auto w-100"
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            height: "50px",
                            maxWidth: "300px",
                          }}
                          type="submit"
                        >
                          RESET
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-0 text-center">
                <p className="text-muted">
                  Remember It ?{" "}
                  <Link to="login" className="text-primary fw-bold">
                    Sign In Here
                  </Link>{" "}
                </p>
              </div>
              <div className="text-center mt-2">
                <img src={cplLogo} height={40}  width={'auto'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
