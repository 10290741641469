import React, { useEffect, useMemo, useRef, useState } from "react"

import Card from "components/Common/Card"
import ContentContainer from "components/Common/ContentContainer"
// import AreaChart from "components/Common/areaChart"
import BarChart from "components/Common/barChart"
import { Col, Row } from "reactstrap"
import styles from "./styles.module.scss"
import CardHeader from "components/Common/CardHeader"
// import UsageCardBody from "components/Card/UsageCardBody"
import TableComponent from "components/Table"
import {
  getBillingKPIFail,
  getEvents,
  getEventsFail,
  getKPI,
  getTenantDetails,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import Loading from "components/Common/Loading"
import NotFound from "components/Common/NotFound"
import { dateFormat, formatCurrency, validateDateRange } from "utils/helper"
import { RESET_DATE } from "store/filters/actionTypes"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import Icon from "@mdi/react"
import { toast } from "react-toastify"
import InfoKeyValue from "./InfoKeyValue"

export default function Billing() {
  const dispatch = useDispatch()
  const isFirstRender = useRef(true)

  const { profile } = useSelector(state => state.Profile)
  const { eventLoading, events, tenantLoading, tenantDetails } = useSelector(state => state.Billing)
  const { startDate, endDate } = useSelector(state => state.Filters)
  const { metaData } = useSelector(state => state.MetaData);


  const { costKWH, costKW, avgCostKWH, totalCostKW, xAxis, loading } =
    useSelector(state => state.Insights)

  const [demandGraph, setDemandGraph] = useState(false)
  const fetchData = () => {
    const dates = {
      page: "billing",
      tenantnum: profile?.tenantnum,
      startDate: moment(startDate, "YYYY-MM-DD")
        .startOf("month")
        ?.format("YYYY-MM-DD"),
      endDate: moment(endDate, "YYYY-MM-DD")
        .endOf("month")
        ?.format("YYYY-MM-DD"),
    }

    if (profile?.tenantnum) {
      const message = validateDateRange(startDate, endDate)
      if (message && !isFirstRender.current) {
        toast.error(message, {
          autoClose: 3000,
        })
        dispatch(getEventsFail())
        return dispatch(getBillingKPIFail())
      } else if (!message) {
        isFirstRender.current ? (isFirstRender.current = false) : ""
        dispatch(getEvents(dates))
        dispatch(getKPI(dates))
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, dateFormat(startDate), dateFormat(endDate), profile?.tenantnum])

  useEffect(() => {
    if (profile?.tenantnum) {
      dispatch(getTenantDetails({tenantnum: profile?.tenantnum }))
    }
  }, [profile?.tenantnum])

  useEffect(() => {
    return () => {
      if (profile?.tenantnum) {
        isFirstRender.current = true
        dispatch({
          type: RESET_DATE,
          payload: "",
        })
      }
    }
  }, [])

  const usageHistoryData = useMemo(() => {
    if (events) {
      const data = _.sortBy(events, "dateend")?.slice(-12)

      const historyYAxis = []
      const historyXAxis = data?.map(el => {
        historyYAxis.push(demandGraph ? el?.demand : el?.energy)

        return el?.dateend
      })

      let min = Math.floor(Math.min(...historyYAxis)) || 0
      min = Math.floor(min / 5) * 5
      let max = Math.ceil(Math.max(...historyYAxis)) || 0
      max = max > 10 ? Math.ceil(max / 5) * 5 : max
      let scale = Math.ceil((max - min) / 5)
      scale = scale > 10 ? Math.ceil(scale / 5) * 5 : scale
      min = min - scale
      min = min < 0 ? 0 : min
      let lastVal = 0
      const yLabels = []

      for (let i = 0; lastVal < max; i++) {
        const stepSize = min + scale * i
        lastVal = stepSize
        yLabels?.push(stepSize)
      }

      yLabels.reverse()

      return { historyXAxis, historyYAxis, min, max, scale, yLabels }
    }
  }, [events, demandGraph])

  const showGraph = () => {
    setDemandGraph(!demandGraph)
  }
  
  return (
    <ContentContainer rangeFilter={true} meta={{ title: "Billing" }}>
      <Row className={styles.container}>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Card>
            <CardHeader 
              heading={"Tenant Information"}
              info={metaData?.["Tenant Information"]}
            />
            {!tenantLoading && tenantDetails && <div className={styles.detailContainer}>
            <InfoKeyValue title={'Tenant Name'} value={tenantDetails?.tenantname || 'N/A'} />
            <InfoKeyValue title={'Address'} value={tenantDetails?.Address || 'N/A'} />
            <InfoKeyValue title={'Square Footage'} value={tenantDetails?.sqft?.toLocaleString() || 0} />
            <InfoKeyValue title={'Average Bill Amount'} value={`$${tenantDetails?.AverageBillAmount || 0}`} />
            <InfoKeyValue title={'Occupancy'} value={tenantDetails?.occupancy || 0} />
            </div>}
            {tenantLoading && <Loading />}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Card>
            <CardHeader
              heading={"Cost / KWH"}
              stats={formatCurrency(avgCostKWH)}
              info={metaData?.["What is Cost/KWH?"]}
            />
            {!loading && costKWH?.length > 0 && (
              <BarChart
                data={{
                  xAxis,
                  yAxis: costKWH,
                }}
                text="Total Cost"
                decimal={3}
                type="cost"
                utility="electric"
                axisTick={true}
                plotOptions={{
                  bar: {
                    columnWidth: "18px",
                    borderRadius: 9,
                    endingShape: "rounded",
                    borderRadiusApplication: "end",
                  },
                }}
              />
            )}
            {loading && <Loading />}
            {!loading && costKWH?.length <= 0 && <NotFound />}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Card>
            <CardHeader
              heading={"Cost / KW"}
              stats={formatCurrency(totalCostKW)}
              info={metaData?.["What is Peak Demand?"]}
            />
            {!loading && costKW?.length > 0 && (
              <BarChart
                data={{
                  xAxis,
                  yAxis: costKW,
                }}
                text="Total Cost"
                type="cost"
                utility="electric"
                yLabel=""
                plotOptions={{
                  bar: {
                    columnWidth: "18px",
                    borderRadius: 9,
                    endingShape: "rounded",
                    borderRadiusApplication: "end",
                    colors: {
                      ranges: [
                        {
                          from: 0,
                          to: 0,
                          color: undefined,
                        },
                      ],
                      backgroundBarOpacity: 1,
                      backgroundBarColors: ["#F0F1F4"],
                      backgroundBarRadius: "16px",
                    },
                  },
                }}
              />
            )}
            {loading && <Loading />}
            {!loading && costKW?.length <= 0 && <NotFound />}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={5} xl={4}>
          <Card>
            <CardHeader
              heading={"Usage History"}
              info={metaData?.["Understand your Usage History"]}
            />
            {!eventLoading && events?.length > 0 && (
              <>
                <BarChart
                  data={{
                    xAxis: usageHistoryData?.historyXAxis,
                    yAxis: usageHistoryData?.historyYAxis,
                  }}
                  text="Total Cost"
                  type="number"
                  utility="electric"
                  yLabel={demandGraph ? "KW" : "KWh"}
                  rotateX={-45}
                  customYAxis={true}
                  chartHeight={324}
                  customUsage={demandGraph}
                  yAxisTickSettings={{
                    min: usageHistoryData?.min,
                    max: usageHistoryData?.max,
                    tickAmount: usageHistoryData?.yLabels?.length - 1 || 0,
                  }}
                  plotOptions={{
                    bar: {
                      columnWidth: "18px",
                      borderRadius: 9,
                      borderRadiusApplication: "end",
                      colors: {
                        ranges: [
                          {
                            from: 0,
                            to: 0,
                            color: undefined,
                          },
                        ],
                        backgroundBarOpacity: 1,
                        backgroundBarColors: ["#F0F1F4"],
                        backgroundBarRadius: "9px",
                      },
                    },
                  }}
                />
                <div className={styles.customYAXContainer}>
                  <div className={styles.axisInnerContainer}>
                    {usageHistoryData?.yLabels?.map(el => (
                      <div key={el}>{el}</div>
                    ))}
                  </div>
                </div>

                {/* <UsageCardBody /> */}

                <div className={`${styles.legendContainer} center`}>
                  {demandGraph && (
                    <Icon path={mdiChevronLeft} size={1} onClick={showGraph} />
                  )}
                  <div
                    className={demandGraph ? styles.legend1 : styles.legend}
                  ></div>
                  <div className={styles.label}>
                    {demandGraph ? "Demand" : "Usage"}
                  </div>
                  {!demandGraph && (
                    <Icon path={mdiChevronRight} size={1} onClick={showGraph} />
                  )}
                </div>
              </>
            )}
            {eventLoading && <Loading />}
            {!eventLoading && events?.length <= 0 && <NotFound />}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={7} xl={8}>
          <Card>
            <CardHeader heading={"Billing History"} desc={true} />
            <TableComponent />
          </Card>
        </Col>
      </Row>
    </ContentContainer>
  )
}
