import React from "react"
import { CardHeader } from "reactstrap"
import { mdiClose } from "@mdi/js"
import Icon from "@mdi/react"

const CardHeaderComponent = ({
  title,
  subtitle,
  alignHeader = "center",
  closeButton = false,
  toggleCloseButton = undefined,
}) => {
  const CloseButton = () => {
    return (
      <span
        onClick={toggleCloseButton}
        style={{ fontSize: "32px" }}
        type="button"
      >
        <Icon path={mdiClose} title="Close Icon" size={1.5} color="#FFF" />
        {/* <i className="mdi mdi-close text-light"></i> */}
      </span>
    )
  }

  return (
    <div className={`text-${alignHeader}`}>
      <CardHeader
        style={{
          backgroundColor: "#3668CC",
          borderTopLeftRadius: "0.3rem",
          borderTopRightRadius: "0.3rem",
          color: "#ffffff",
        }}
        className={subtitle === "Usage History" ? "py-4" : ""}
      >
        {(title || closeButton) && (
          <h3 style={{ margin: "0px" }}>
            <span style={{ fontStyle: "italic", fontWeight: "100" }}>
              {title}
            </span>
            <span style={{ float: "right", fontWeight: "24px" }}>
              {closeButton && CloseButton()}
            </span>
          </h3>
        )}
        <h2 style={{ margin: "0px" }} className="text-uppercase fw-bolder">
          {subtitle}
        </h2>
      </CardHeader>
    </div>
  )
}

export default CardHeaderComponent
