import {
  GET_KPI_REQUEST,
  GET_INSIGHTS_SUCCESS,
  GET_INSIGHTS_FAIL,
  GET_BILLING_KPI_SUCCESS,
  GET_BILLING_KPI_FAIL,
  UPDATE_OCCUPANCY_COST
} from "./actionTypes"
import _ from "lodash"
import { calcAverage, calcSum } from "utils/helper"

const emptyObj = {percentage: 0, avg: 0}

const initialStage = {
  // billing
  totalCostKW: 0,
  costKW: [],
  
  // efficiency Insight
  totalKWH: [],
  peakDemand: [],
  totalCost: [],
  sumTotalKWH: 0,
  demandPeakValue: 0,
  sumTotalCost: 0,
  costOccupant: emptyObj,
  emissionOccupant: emptyObj,
  perOccupant: emptyObj,
  loadFactor: emptyObj,
  kwhSqFt: emptyObj,
  wattsSqFt: emptyObj,
  costSqFt: emptyObj,
  emission: emptyObj,
  
  // common
  avgCostKWH: 0,
  costKWH: [],
  xAxis: [],
  rawData: [],
  loading: false,
  error: "",
}

const Insights = (state = initialStage, action) => {
  switch (action.type) {
    case GET_KPI_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case GET_BILLING_KPI_SUCCESS:
      return {
        ...state,
        costKWH: action.payload?.costKWH,
        costKW: action.payload?.costKW,
        rawData: action.payload?.rawData,
        avgCostKWH: action.payload?.avgCostKWH,
        totalCostKW: action.payload?.totalCostKW,
        xAxis: action.payload?.xAxis,
        loading: false,
      }

    case GET_BILLING_KPI_FAIL:
      return {
        ...state,
        costKWH: [],
        costKW: [],
        rawData: [],
        xAxis: [],
        avgCostKWH: 0,
        totalCostKW: 0,
        error: action.payload,
        loading: false,
      }
    case GET_INSIGHTS_SUCCESS: {
      const {payload} = action;

      return {
        ...state,
        rawData: payload?.rawData,
        xAxis: payload?.xAxis,
        costKWH: payload?.costKWH,
        totalKWH: payload?.totalKWH,
        peakDemand: payload?.peakDemand,
        totalCost: payload?.totalCost,
        sumTotalKWH: payload?.sumTotalKWH,
        demandPeakValue: payload?.demandPeakValue,
        avgCostKWH: payload?.avgCostKWH,
        sumTotalCost: payload?.sumTotalCost,
        costOccupant: payload?.costOccupant,
        emissionOccupant: payload?.emissionOccupant,
        perOccupant: payload?.perOccupant,
        loadFactor: payload?.loadFactor,
        kwhSqFt: payload?.kwhSqFt,
        wattsSqFt: payload?.wattsSqFt,
        costSqFt: payload?.costSqFt,
        emission: payload?.emission,
        
        loading: false,
      }
      }

    case GET_INSIGHTS_FAIL:
      return {
        ...state,
        costKWH: [],
        rawData: [],
        xAxis: [],
        totalKWH: [],
        peakDemand: [],
        totalCost: [],
        sumTotalKWH: 0,
        demandPeakValue: 0,
        avgCostKWH: 0,
        sumTotalCost: 0,
        costOccupant: emptyObj,
        emissionOccupant: emptyObj,
        perOccupant: emptyObj,
        loadFactor: emptyObj,
        kwhSqFt: emptyObj,
        wattsSqFt: emptyObj,
        costSqFt: emptyObj,
        emission: emptyObj,
        error: action.payload,
        loading: false,
      }
    case UPDATE_OCCUPANCY_COST: {
      const data = state?.rawData;
      let calCostOcc = calcAverage('totalcost', data, action.payload);
      let calEmissionOcc = calcAverage('electricemissions', data, action.payload);
      let calPerOcc = calcSum('totalkwh', data, action.payload);
      
      console.log(calCostOcc, calEmissionOcc, calPerOcc)
      return {
        ...state,
        costOccupant: calCostOcc,
        emissionOccupant: calEmissionOcc,
        perOccupant: calPerOcc,
      }
    }
    default:
      return state
  }
}

export default Insights
