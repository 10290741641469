import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./billing/saga"
import ldpSaga from "./LDP/saga"
import newPasswordSaga from "./auth/newpwd/saga"
import kpiSaga from "./Insights/saga"
import filtersSaga from "./filters/saga"
import metaDataSaga from "./metaData/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(newPasswordSaga),
    fork(calendarSaga),
    fork(ldpSaga),
    fork(kpiSaga),
    fork(filtersSaga),
    fork(metaDataSaga),
  ])
}
