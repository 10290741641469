import { Link } from "react-router-dom"
import cplLogo from "../../assets/images/cpl-logo-removebg.jpg"

const TermsOfUsePage = () => {
  return (
    <>
      <div className="logo-lg text-start">
        <Link to="/login">
          <img src={cplLogo} alt="" height="45em" width="auto" />
        </Link>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="w-75 text-center">
          <h1>Terms of Use</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis
            tortor turpis. Phasellus consectetur, nunc nec lacinia aliquam, enim
            est consectetur libero, at pharetra sapien nunc sed sem. In faucibus
            nisi ut dolor pharetra, a auctor ex posuere. Maecenas nec risus
            venenatis, finibus lorem non, accumsan dui. Praesent laoreet ex et
            massa maximus, eu tempus mi condimentum.
          </p>
          <p>
            Sed a mauris sed lectus eleifend interdum. Mauris facilisis eu mi ac
            gravida. Nunc ut nisl fermentum, facilisis sem eget, placerat nunc.
            Nulla facilisi. Nulla tincidunt consectetur elit non placerat.
            Integer id felis eget purus varius vestibulum. Nulla semper diam in
            odio consequat suscipit.
          </p>
          <p>
            Sed malesuada augue a lorem fringilla, in convallis lectus pharetra.
            Pellentesque at sagittis est. Duis sed orci lacus. Vestibulum at
            augue et purus interdum vestibulum. Maecenas auctor ut risus eu
            blandit. Donec ut luctus ex. Vestibulum ante ipsum primis in
            faucibus orci luctus et ultrices posuere cubilia curae; Fusce
            consectetur risus nec ante tincidunt, ac aliquam odio consectetur.
          </p>
          <p>
            Proin vitae fringilla tellus, id consectetur felis. Nullam non
            tempor velit. Mauris congue tellus in lectus faucibus, in fermentum
            quam aliquam. Donec vulputate est et enim ullamcorper aliquet.
            Suspendisse potenti. Aenean et dolor sem. Nam eleifend nunc at
            tortor luctus, eu finibus nunc efficitur. Sed auctor metus at arcu
            euismod, nec dapibus purus egestas. Vivamus a pellentesque odio, at
            lobortis eros. In elementum metus in urna cursus vestibulum.
          </p>
          <p>
            Praesent malesuada faucibus aliquet. Fusce quis lectus vitae nisl
            pellentesque fringilla eu vitae mauris. Sed in elit tortor. Cras
            varius eros id pulvinar congue. Integer ac metus eget metus
            vulputate commodo ac in nulla. Fusce at mauris auctor, tincidunt
            quam sit amet, accumsan justo. Integer in neque justo. Donec id
            risus luctus, tempor arcu sed, volutpat magna. Duis hendrerit augue
            nisl, eget vestibulum arcu vulputate eget. In vitae mauris arcu.
            Suspendisse potenti. Fusce non ante vel sapien vulputate laoreet vel
            in enim. Suspendisse potenti.
          </p>
        </div>
      </div>
    </>
  )
}

export default TermsOfUsePage
