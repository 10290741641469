import { Spinner } from "reactstrap";
import styles from './styles.module.scss';

export default function NotFound() {
  return (
    <div
      className={`${styles.container} center`}
    >
      <p>No Data to Show</p>
    </div>
  )
}
