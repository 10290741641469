import React, { forwardRef, useState } from "react"
import DatePicker from "react-datepicker"
import "./styles.scss"
import styles from "./dateRange.module.scss"
import { ReactComponent as LeftIcon } from "../../../assets/images/datePicker/leftIcon.svg"
import { ReactComponent as RightIcon } from "../../../assets/images/datePicker/rightIcon.svg"

import "react-datepicker/dist/react-datepicker.css"

// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const CustomDatePicker = ({
  label,
  start,
  date,
  setDate,
  apply,
  cancel,
}) => {
  const [open, setOpen] = useState(false)

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`${styles.inputBtn} ${
        start ? styles.rounded_start : styles.rounded_end
      } ${open && styles.active}`}
      onClick={() => {
        setOpen(!open)
      }}
      ref={ref}
    >
      {label}
    </button>
  ))

  const handleClose = () => {
    setOpen(false)
    cancel()
  }

  return (
    <>
      <DatePicker
        selected={date}
        onChange={date => setDate(date)}
        // dateFormat="MM/yyyy"
        showMonthYearPicker
        customInput={<CustomInput />}
        onClickOutside={handleClose}
        popperPlacement="bottom-start"
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
          nexYearButtonDisabled,
        }) => (
          <div className={styles.dateHeader}>
            <button onClick={decreaseYear}>
              <LeftIcon height={17} width={17} />
            </button>
            <div className={styles.yearText}>{date.getFullYear()}</div>
            <button onClick={increaseYear} disabled={nexYearButtonDisabled}>
              <RightIcon height={17} width={17} />
            </button>
          </div>
        )}
        renderMonthContent={(month, shortMonth) => {
          return <span className={styles.monthStyle}>{shortMonth}</span>
        }}
        onCalendarClose={handleClose}
        onCalendarOpen={() => setOpen(true)}
        shouldCloseOnSelect={false}
        open={open}
      >
        <div className={styles.datePickerFooter}>
          <button
            onClick={() => {
              setDate(new Date())
              setOpen(false)
              apply(start, new Date())
            }}
          >
            Current Month
          </button>
          <button
            onClick={() => {
              setOpen(false)
              apply(start, date)
            }}
          >
            Apply
          </button>
        </div>
      </DatePicker>
    </>
  )
}

export default CustomDatePicker
