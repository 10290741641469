import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { connect, useDispatch, useSelector } from "react-redux"

import { Link } from "react-router-dom"

// Reactstrap
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import cplLogo from "../../assets/images/cpl-logo-removebg.jpg"
import styles from './layout.module.scss';

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getProfileDetails,
} from "../../store/actions"

const Header = props => {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.Profile)
  const [search, setsearch] = useState(false)
  const [createmenu, setCreateMenu] = useState(false)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    if (!profile?.email) {
      dispatch(getProfileDetails(user))
    }
  }, [profile])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <Link to="/" className="logo logo-light align-self-center">
              <span className="logo-sm">
                <img src={cplLogo} alt="" height="40em" width="auto" />
              </span>
              <span className="logo-lg">
                <img src={cplLogo} alt="" height="31.5px" width="282.57px" />
              </span>
            </Link>
          </div>
          <div className="d-flex">
            {/* <div className="flex align-self-center"> */}
              <span className={`fs-5 ${styles.headerUN}`}>
                {profile?.firstname
                  ? `${profile?.firstname || ""} ${profile?.lastname || ""}`
                  : "Account Name"}
              </span>
            {/* </div> */}
            <ProfileMenu profile={profile} />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
