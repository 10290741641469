import React, { useState } from "react"
import { Modal, Button } from "reactstrap"
import CardComponent from "components/Card"
import { useDispatch, useSelector } from "react-redux"
import { logoutUser } from "store/actions"

const LogoutModal = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { profile } = useSelector(state => state.Profile)

  const handleLogout = async() => {
    setLoading(true);
    !loading && dispatch(logoutUser(profile));
  }
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <CardComponent
          title="Tenant"
          subtitle="Logout"
          closeButton={modal}
          toggleCloseButton={toggle}
        >
          <h2>Are you sure?</h2>
          {/* <p>You want to be logged out!</p> */}
          <Button
            color="primary"
            className="m-2"
            onClick={handleLogout}
            style={{ width: "103px" }}
            disabled={loading}
          >
            Yes, Logout!
          </Button>
          <Button
            color="secondary"
            className="m-2"
            onClick={toggle}
            style={{ width: "103px" }}
          >
            No, Cancel!
          </Button>
        </CardComponent>
      </Modal>
    </>
  )
}

export default LogoutModal
