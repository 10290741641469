import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL
const AUTH_API_URL = process.env.REACT_APP_SECOND_BASE_URL
const BILLING_API_URL = process.env.REACT_APP_BILLING_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})
const axiosAuthApi = axios.create({
  baseURL: AUTH_API_URL,
})
const axiosBillingApi = axios.create({
  baseURL: BILLING_API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

axiosApi.interceptors.request.use(
  config => {
    config.headers["Authorization"] =
      "Bearer " + window.localStorage.getItem("token")
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosBillingApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

axiosBillingApi.interceptors.request.use(
  config => {
    config.headers["Authorization"] =
      "Bearer " + window.localStorage.getItem("token")
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosAuthApi.interceptors.request.use(
  config => {
    config.headers["Authorization"] =
      "Bearer " + window.localStorage.getItem("token")
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosAuthApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(
  url,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function billingget(
  url,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  return await axiosBillingApi
    .get(url, { ...config })
    .then(response => response.data)
}

export async function post(
  url,
  data,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function authpost(
  url,
  data,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  return axiosAuthApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(
  url,
  data,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(
  url,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
