import {
    UPDATE_OCCUPANCY_FAIL,
    UPDATE_OCCUPANCY_REQUEST,
    UPDATE_OCCUPANCY_SUCCESS
 } from './actionTypes';
 
 
 export const updateOccupancy = data => ({
     type: UPDATE_OCCUPANCY_REQUEST,
     payload: data
   })
   
   export const updateOccupancySuccess = occupancy => ({
     type: UPDATE_OCCUPANCY_SUCCESS,
     payload: occupancy,
   })
   
   export const updateOccupancyFail = () => ({
    type: UPDATE_OCCUPANCY_FAIL,
    payload: ''
  })
 