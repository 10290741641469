import MetaTags from 'react-meta-tags';
import LoadProfileComponents from '../../components/LoadProfile';

const LoadProfile = () => {

  return (
    <>
      <MetaTags>
        <title>GENERGY | Load Profile</title>
      </MetaTags>
      <LoadProfileComponents />

    </>
  )
}

export default LoadProfile;