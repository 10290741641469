import React from "react";
import { Row, Col } from "reactstrap";
import styles from "./styles.module.scss"
import OccupancyDropDown from "components/Insights/OccupancyDrop"
import DateRangePicker from "../DateRangePicker"
import YearPicker from "../YearPicker";
import InfoBar from "../InfoBar";

export default function ContentContainer({children, rangeFilter, occupancyFilter, yearFilter, meta, info}) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Row className={styles.headerInnerContainer}>
          <Col xs={12} sm={12} md={4} lg={3}>
            <p className={styles.heading}>{meta?.title} {info && <InfoBar info={info} />}</p>
            {/* <div className={styles.breadcrumb}>
              <Link to="/billing">Home</Link>
              <Icon path={mdiChevronRight} size={1} />
              <p>{meta?.name}</p>
            </div> */}
          </Col>
          <Col xs={12} sm={12} md={8} lg={9}>
            <div className={styles.filtersContainer}>
              {yearFilter && <YearPicker />}
              {occupancyFilter && <OccupancyDropDown />}
              {rangeFilter && <DateRangePicker />}
            </div>
          </Col>
        </Row>
 
      </div>
      <div className={styles.contentArea}>
        {children}
      </div>
    </div>
  )
}
