import CardComponent from "components/Card"
import { saveAs } from "file-saver"
import { useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Button, Modal } from "reactstrap"

const InvoiceModal = ({ modal, toggle, invoiceData }) => {
  const [fileUrl, setFileUrl] = useState("")
  const [error, setError] = useState(false)

  const { singleEventDetail } = useSelector(state => state.Billing)

  useEffect(() => {
    setFileUrl(singleEventDetail?.s3Link)
  }, [singleEventDetail?.s3Link])

  useEffect(() => {
    // if (!modal) {
    setFileUrl("")
    // }
  }, [modal])

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setError(false)
    setNumPages(numPages)
    setPageNumber(1)
  }

  const handleDocumentLoadError = () => {
    // console.error("Error occurred while loading the document", error)
    toast.error("Error occurred while loading the document")
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <CardComponent
          title="Tenant"
          subtitle="INVOICE"
          closeButton={modal}
          toggleCloseButton={toggle}
        >
          {fileUrl && (
            <div style={{ border: "3px solid #3668CC", textAlign: "center" }}>
              <Document
                file={fileUrl}
                onError={handleDocumentLoadError}
                error={() => {
                  setError(true)
                  return "Invoice is not available. Please contact support at rb@cplems.com"
                }}
              >
                <Page
                  pageNumber={pageNumber}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={handleDocumentLoadError}
                />
              </Document>
            </div>
          )}
          {fileUrl ? (
            <Button
              disabled={error}
              onClick={() => {
                saveAs(fileUrl, "invoice.pdf")
              }}
              className="btn border border-none rounded-5 mt-5 fs-2 px-5"
              style={{ backgroundColor: "#3668CC", color: "#fff" }}
            >
              DOWNLOAD INVOICE
            </Button>
          ) : (
            <p>Loading...</p>
          )}
          {/* <Button
            className="btn border border-none rounded-5 mt-5 fs-2 px-5"
            style={{ backgroundColor: "#ff8b6d", color: "#fff" }}
          >
            DOWNLOAD INVOICE
          </Button> */}
        </CardComponent>
      </Modal>
    </>
  )
}

export default InvoiceModal
