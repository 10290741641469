import { call, put, takeEvery } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_EVENT,
  DELETE_EVENT,
  GET_CATEGORIES,
  GET_EVENTS,
  GET_SINGLE_EVENTS_DETAILS,
  GET_TENANT_DETAILS,
  LATEST_EVENT,
  UPDATE_EVENT,
} from "../billing/actionTypes"
import {
  addEventDetails,
  addEventFail,
  addEventSuccess,
  deleteEventFail,
  deleteEventSuccess,
  getCategoriesFail,
  getCategoriesSuccess,
  getEventsFail,
  getEventsSuccess,
  getTenantDetails,
  getTenantDetailsFail,
  getTenantDetailsSuccess,
  isEventLoading,
  isLoading,
  latestEventData,
  updateEventFail,
  updateEventSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { toast } from "react-toastify"
import {
  addNewEvent,
  deleteEvent,
  getBillingData,
  getCategories,
  getSingleEventDetails,
  updateEvent,
} from "../../helpers/fakebackend_helper"
import axiosServices from "helpers/axios"


const fetchTenantDetail = async ({ tenantnum }) =>
  await axiosServices.get(
    `${process.env.REACT_APP_TENANT_DATA_URL}/${tenantnum}`
  )

function* fetchEvents({ payload: data }) {
  try {
    yield put(isLoading(true))
    const response = yield call(getBillingData, data)
    yield put(isLoading(false))
    yield put(getEventsSuccess(response))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token or Expire Token" ||
      errorMessage === "Invalid session" ||
      errorMessage === "Invalid Token" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      console.log("errorMessage", errorMessage)
      if (!toast.isActive("session")) {
        toast.error(errorMessage, {
          autoClose: 3000,
          toastId: "session",
        })
      }
      // localStorage.removeItem("authUser")
      // localStorage.removeItem("token")
      // window.location.href = "/login"
      yield put(isLoading(false))
      yield put(getEventsFail(error))
      return
    }
    console.log("error", error)
    yield put(isLoading(false))
    toast.error(errorMessage, {
      autoClose: 3000,
    })
    // yield put(apiError(error))
    yield put(getEventsFail(error))
  }
}

function* fetchLatestEvents({ payload: data }) {
  try {
    yield put(isEventLoading(true))
    const response = yield call(getBillingData, data)
    yield put(isEventLoading(false))
    // toast.success(response?.message)
    yield put(latestEventData(response?.data))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token or Expire Token" ||
      errorMessage === "Invalid Token" ||
      errorMessage === "Invalid session" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      console.log("errorMessage", errorMessage)
      // localStorage.removeItem("authUser")
      // localStorage.removeItem("token")
      // window.location.href = "/login"
      yield put(isEventLoading(false))
      return
    }
    console.log("error", error)
    yield put(isEventLoading(false))
    // toast.error(errorMessage)
    // yield put(apiError(error))
    yield put(getEventsFail(error))
  }
}

function* fetchSingleEventDetails({ payload: data }) {
  try {
    yield put(isEventLoading(true))
    const response = yield call(getSingleEventDetails, data)
    yield put(isEventLoading(false))
    yield put(addEventDetails(response))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token or Expire Token" ||
      errorMessage === "Invalid session" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      console.log("errorMessage", errorMessage)

      if (!toast.isActive("session")) {
        toast.error(errorMessage, {
          autoClose: 3000,
          toastId: "session",
        })
      }
      // localStorage.removeItem("authUser")
      // localStorage.removeItem("token")
      // window.location.href = "/login"
      yield put(isEventLoading(false))
      return
    }
    console.log("error", error)
    yield put(isEventLoading(false))
    toast.error(errorMessage, {
      autoClose: 1000,
    })
    // yield put(apiError(error))
    yield put(getEventsFail(error))
  }
}

function* onAddNewEvent({ payload: event }) {
  try {
    const response = yield call(addNewEvent, event)
    yield put(addEventSuccess(response))
  } catch (error) {
    yield put(addEventFail(error))
  }
}

function* onUpdateEvent({ payload: event }) {
  try {
    const response = yield call(updateEvent, event)
    yield put(updateEventSuccess(response))
  } catch (error) {
    yield put(updateEventFail(error))
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    const response = yield call(deleteEvent, event)
    yield put(deleteEventSuccess(response))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

function* onGetCategories() {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchTenantDetails({tenantnum}) {
  try {
    const response = yield call(fetchTenantDetail, {tenantnum});    
    yield put(getTenantDetailsSuccess(response?.data?.data?.[0]))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    toast.error(errorMessage, {
      autoClose: 3000,
    })
    yield put(getTenantDetailsFail(error))
  }
}

function* calendarSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents)
  yield takeEvery(GET_TENANT_DETAILS, fetchTenantDetails)
  yield takeEvery(LATEST_EVENT, fetchLatestEvents)
  yield takeEvery(GET_SINGLE_EVENTS_DETAILS, fetchSingleEventDetails)
  yield takeEvery(ADD_NEW_EVENT, onAddNewEvent)
  yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  yield takeEvery(DELETE_EVENT, onDeleteEvent)
  yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default calendarSaga
