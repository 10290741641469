import { all, call, fork, put, takeEvery } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserFailed, registerUserSuccessful } from "./actions"

//Include Both Helper File with needed methods
import { toast } from "react-toastify"
import { register } from "../../../helpers/fakebackend_helper"
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(register, user, user?.tenantId)
    yield put(registerUserSuccessful(response))
    toast.success(response.message, {
      autoClose: 1000,
    })
    history?.push("/login")
  } catch (error) {
    console.log("error", error)
    const errorMessage =
      error?.response?.data?.message || "Something went wrong"
    toast.error(errorMessage, { autoClose: 3000 })
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
