import { call, put, takeEvery } from "redux-saga/effects"
import { getBillingKPIFail, getBillingKPISuccess, getInsightsFail, getInsightsSuccess } from "./actions"
import { updateOccupancySuccess } from "store/actions"
import { toast } from "react-toastify"
import axiosServices from "helpers/axios"
import _ from "lodash"
import { calcAverage, calcSum } from "utils/helper"

const { GET_KPI_REQUEST } = require("./actionTypes")

const callKPI = async ({ tenantnum, startDate, endDate }) =>
  await axiosServices.get(
    `${process.env.REACT_APP_KPI_URL}/${tenantnum}?startDate=${startDate}&endDate=${endDate}`
  )

function* fetchKPI({ payload: data }) {
  try {
    const response = yield call(callKPI, data)

    const res = _.sortBy(response?.data?.data, "dateend");

    let xAxis = []
    let rawData = res || []

    if (data?.page === "insights") {
      let totalKWH = []
      let peakDemand = []
      let totalCost = []
      let costKWH = []

      const emptyObj = {percentage: 0, avg: 0}
      let costOccupant = calcAverage('totalcost', res, res?.[0]?.occupancy) || emptyObj;
      let emissionOccupant = calcAverage('electricemissions', res, res?.[0]?.occupancy) || emptyObj;
      let perOccupant = calcSum('totalkwh', res, res?.[0]?.occupancy) || emptyObj;
      let loadFactor = calcAverage('load_factor', res) || emptyObj;
      let kwhSqFt = calcAverage('kwhsqft', res) || emptyObj;
      let wattsSqFt= calcAverage('wattsqft', res) || emptyObj;
      let costSqFt=calcAverage('costsqft', res) || emptyObj;
      let emission= calcAverage('electricemissions', res) || emptyObj;

      // costOccupant = {...costOccupant, avg: formatNumber((_.meanBy(res, 'totalcost'))/res?.[0]?.occupancy || 0)}
      // emissionOccupant = {...emissionOccupant, avg: formatNumber((_.meanBy(res, 'electricemissions'))/res?.[0]?.occupancy || 0)}
      // perOccupant = {...emissionOccupant, avg: formatNumber((_.meanBy(res, 'totalkwh'))/res?.[0]?.occupancy || 0)}

      xAxis = res?.map(el => {
        totalKWH.push(el?.totalkwh)
        peakDemand.push(el?.totalkw)
        totalCost.push(el?.totalcost)
        costKWH.push(el?.costkwh);

        return el?.dateend
      })

      const sumTotalKWH = _.sum(totalKWH)
      const demandPeakValue = _.max(peakDemand)
      const sumTotalCost = _.sum(totalCost)
      const  avgCostKWH = _.mean(costKWH)
      yield put(
        getInsightsSuccess({
          totalKWH,
          peakDemand,
          totalCost,
          costKWH,
          sumTotalKWH,
          demandPeakValue,
          sumTotalCost,
          avgCostKWH,
          costOccupant,
          emissionOccupant,
          perOccupant,
          loadFactor,
          kwhSqFt,
          wattsSqFt,
          costSqFt,
          emission,
          xAxis,
          rawData
        })
      )
    } else {
      let costKWH = []
      let costKW = []

      xAxis = res?.map(el => {
        costKWH.push(el?.costkwh)
        costKW.push(el?.costkw)
        return el?.dateend
      })

      const  avgCostKWH = _.mean(costKWH)
      const totalCostKW = _.mean(costKW)
      yield put(
        getBillingKPISuccess({
          costKWH,
          costKW,
          xAxis,
          rawData,
          avgCostKWH,
          totalCostKW,
        })
      )
    }

    yield put(updateOccupancySuccess(res?.[0]?.occupancy))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }

    toast.error(errorMessage, {
      autoClose: 3000,
    })
    if (data?.page === "insights") {
      yield put(getInsightsFail(error?.response))
    }else{
      yield put(getBillingKPIFail(error?.response))
    }
  }
}

function* KpiSaga() {
  yield takeEvery(GET_KPI_REQUEST, fetchKPI)
}

export default KpiSaga
