import { all, call, fork, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE } from "./actionTypes"
import { editProfileFlag, profileError, profileSuccess } from "./actions"

//Include Both Helper File with needed methods
import { toast } from "react-toastify"
import {
  editProfileData,
  getProfileData,
} from "../../../helpers/fakebackend_helper"
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { userNewPasswordError } from "../newpwd/actions"
// import { updateLDPDataDate } from "store/actions"

// const fireBaseBackend = getFirebaseBackend()

function* getProfile({ payload: { user } }) {
  if (!user) return
  try {
    const response = yield call(getProfileData, user)
    // yield put(updateLDPDataDate(response?.data?.recentDate?.[0]?.['max(`time(utc)`)']))
    yield put(profileSuccess(response.data))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token" ||
      errorMessage === "Invalid session" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      console.log("errorMessage", errorMessage)
      localStorage.removeItem("authUser")
      localStorage.removeItem("token")
      window.location.href = "/login"
      yield put(userNewPasswordError(error))
      return
    }
    console.log("error", errorMessage)
    yield put(userNewPasswordError(error))
  }
}

function* editProfile({ payload: { user, values } }) {
  try {
    const response = yield call(editProfileData, { user, values })
    toast.success(response.message, {
      autoClose: 1000,
    })
    yield put(profileSuccess(response.data))
    yield put(editProfileFlag(true))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"

    console.log("error", error)
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token" ||
      errorMessage === "Invalid session" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      console.log("errorMessage", errorMessage)
      if (!toast.isActive("session")) {
        toast.error(errorMessage, {
          autoClose: 3000,
          toastId: "session",
        })
      }
      localStorage.removeItem("authUser")
      localStorage.removeItem("token")
      window.location.href = "/login"
      yield put(profileError(error))
      return
    }
    toast.error(errorMessage, {
      autoClose: 1000,
    })
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_PROFILE, getProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
