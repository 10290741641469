import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
  userForgetPasswordReset,
} from "../../store/actions"

// Redux
import { connect, useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"

// import images
import { ImEye, ImEyeBlocked } from "react-icons/im"
import cplLogo from "../../assets/images/cpl-logo-removebg.jpg"

const NewPassword = props => {
  const dispatch = useDispatch()
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false)
  const [isPasswordShow, setIsPasswordShow] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("token")

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    // props.registerUser(values)
    dispatch(userForgetPasswordReset({ ...values, forgot_token: token }))
  }

  useEffect(() => {
    props.apiError("")
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>New Password | GENERGY</title>
      </MetaTags>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mb-2">
                <CardBody className="pt-0 px-0">
                  <div className=" pb-1" style={{ backgroundColor: "#3668CC" }}>
                    <h4
                      className="pt-1 text-white font-size-15 font-italic mb-0 text-center fw-normal !important"
                      style={{ fontStyle: "italic" }}
                    >
                      Tenant
                    </h4>
                    <h2 className="text-white text-center fw-bold">
                      NEW PASSWORD
                    </h2>
                  </div>
                  <div className="p-3">
                    {/* <h4 className="text-muted font-size-18 mb-0 text-center fw-bold">
                      your_email@email.com !
                    </h4> */}
                    <p className="text-muted text-center mt-0">
                      Please enter new password below
                    </p>
                    <AvForm
                      className="form-horizontal mt-4 mx-3"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <div className="mb-3 position-relative">
                        <AvField
                          d="password"
                          name="password"
                          label="New Password"
                          className="form-control"
                          placeholder="Enter New Password"
                          type={isPasswordShow ? "text" : "password"}
                          required
                        />
                        <span
                          className={`fs-3 position-absolute`}
                          style={{
                            top: "27px",
                            right: "10px",
                            cursor: "pointer",
                            opacity: "0.3",
                          }}
                          onClick={() => setIsPasswordShow(!isPasswordShow)}
                        >
                          {isPasswordShow ? <ImEye /> : <ImEyeBlocked />}
                        </span>
                      </div>

                      <div className="mb-3 position-relative">
                        <AvField
                          d="confirmPassword"
                          name="confirmPassword"
                          label="Confirm New Password"
                          className="form-control"
                          placeholder="Enter New Password"
                          type={isConfirmPasswordShow ? "text" : "password"}
                          required
                        />
                        <span
                          className={`fs-3 position-absolute`}
                          style={{
                            top: "27px",
                            right: "10px",
                            cursor: "pointer",
                            opacity: "0.3",
                          }}
                          onClick={() =>
                            setIsConfirmPasswordShow(!isConfirmPasswordShow)
                          }
                        >
                          {isConfirmPasswordShow ? <ImEye /> : <ImEyeBlocked />}
                        </span>
                      </div>

                      <div className=" mt-5 px-5 d-flex justify-content-center">
                        <button
                          className="btn btn-primary waves-effect waves-light btn-lg rounded-pill w-100 mx-auto max-width-250"
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            height: "50px",
                            maxWidth: "300px",
                          }}
                          type="submit"
                        >
                          SAVE
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center">
                <p className="text-muted mb-0 font-size-14">
                  Remember it? &nbsp;
                  <Link to="/login" className="text-primary">
                    Sign in Here
                  </Link>
                </p>
              </div>
              <div className="text-center mt-2">
                <img src={cplLogo} height={40}  width={'auto'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NewPassword.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(NewPassword)
