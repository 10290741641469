import moment from "moment"
import {
  SET_START_DATE,
  SET_END_DATE,
  RESET_DATE,
  UPDATE_OCCUPANCY_REQUEST,
  UPDATE_OCCUPANCY_SUCCESS,
  UPDATE_OCCUPANCY_FAIL
} from "./actionTypes"

const currentDate = moment()

const INIT_STATE = {
  startDate: new Date(currentDate.subtract(1, 'year')),
  endDate: new Date(),
  occupancy: 1,
  loading: false
}

const Filters = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_START_DATE: {
      return {
        ...state,
        startDate: action.payload,
      }
    }
    case SET_END_DATE: {
      return {
        ...state,
        endDate: action.payload,
      }
    }
    case RESET_DATE: {
      return {
        ...state,
        startDate: new Date(moment().subtract(1, 'year')),
        endDate: new Date(),
      }
    }
    case UPDATE_OCCUPANCY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_OCCUPANCY_SUCCESS:
      return {
        ...state,
        occupancy: action.payload,
        loading: false
      }
    case UPDATE_OCCUPANCY_FAIL:
        return {
          ...state,
          loading: false,
      }
    default:
      return state
  }
}

export default Filters;
