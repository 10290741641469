import {
  GET_LDP_REQUEST,
  GET_LDP_SUCCESS,
  GET_LDP_FAIL,
  SET_YEAR,
  LDP_AVAILABLE_DATA_DATE,
} from "./actionTypes"

const initialStage = {
  ldp: {},
  rawData: [],
  selectedYear: new Date(),
  ldpDataDate: new Date(),
  loading: false,
  fetchingDate: false,
  error: "",
}

const LDP = (state = initialStage, action) => {
  switch (action.type) {
    case GET_LDP_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case GET_LDP_SUCCESS:
      return {
        ...state,
        ldp: {
          metersData: action?.payload?.metersData,
          xAxis: action?.payload?.xAxis,
          meterIds: action?.payload?.meterIds,
        },
        rawData: action.payload?.rawData,
        loading: false,
      }

    case GET_LDP_FAIL:
      return {
        ...state,
        ldp: {},
        rawData: [],
        error: action.payload,
        loading: false,
      }
    case SET_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      }
    case LDP_AVAILABLE_DATA_DATE:
      return {
        ...state,
        // ldpDataDate: new Date(action.payload),
        // selectedYear: new Date(action.payload),
        fetchingDate: false,
      }

    default:
      return state
  }
}

export default LDP
