import React from "react"
import { Card, CardBody } from "reactstrap"
import CardHeaderComponent from "./Header"

const CardComponent = ({
  children,
  title,
  subtitle,
  alignHeader,
  alignContent = "center",
  closeButton,
  toggleCloseButton,
}) => {
  return (
    <Card
      className="shadow"
      style={{ height: subtitle === "Billing History" ? "97.5%" : "" }}
    >
      <CardHeaderComponent
        title={title}
        subtitle={subtitle}
        alignHeader={alignHeader}
        closeButton={closeButton}
        toggleCloseButton={toggleCloseButton}
      />
      <CardBody className={`text-${alignContent}`}>{children}</CardBody>
    </Card>
  )
}

export default CardComponent
