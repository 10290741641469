import Card from "../Card"
import styles from "./styles.module.scss"
import { ReactComponent as PercentIncArrow } from "../../../assets/images/insights/percentIncArrow.svg"
import InfoBar from "../InfoBar"

export default function StateCard({ title, stats, icon, currency, info }) {
  return (
    <Card padding={'16px 8px 16px 8px'}>
      <div className={styles.container}>
        <p className={styles.title}>{title +' '}{info && <InfoBar info={info} />}</p>
        <div className={styles.statsContainer}>
          <p className={styles.stats}>
            {currency ? `$${stats?.avg}` : stats?.avg}
            <sup className={stats?.percentage < 0 ? styles.down : undefined}>
              {`${stats?.percentage}%`} {stats?.percentage === 0 ?  '' : <PercentIncArrow  height={24} width={24} /> }
            </sup>
          </p>
          <div className={`${styles.icon} center`}>{icon}</div>
        </div>
      </div>
    </Card>
  )
}
