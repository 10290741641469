import Card from "components/Common/Card"
import ContentContainer from "components/Common/ContentContainer"
import CustomSlider from "components/Common/CustomSlider"
import Loading from "components/Common/Loading"
import NotFound from "components/Common/NotFound"
import LineChart from "components/Common/lineChart"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import { SET_YEAR } from "store/LDP/actionTypes"
import { getLDP } from "store/actions"
import { getDaysListInMonth } from "utils/helper"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]


export default function LoadProfile() {
  const dispatch = useDispatch()
  const {
    selectedYear: year,
    ldp,
    loading,
    ldpDataDate,
    fetchingDate
  } = useSelector(state => state.LDP)
  const { profile } = useSelector(state => state.Profile)
  const { metaData } = useSelector(state => state.MetaData);
  const [month, setMonth] = useState(null)
  const [day, setDay] = useState(null);
  const [meter, setMeter] = useState(1);


  useEffect(() => {
    if (profile?.tenantnum && month) {
      const selectedDate = moment(
        `${year?.getFullYear()}-${month}-${day}`,
        '"YYYY-MM-DD'
      ).format("YYYY-MM-DD")
      if (selectedDate === "Invalid date") {
        const lastDay = moment(`${year?.getFullYear()}-${month}-01`)
          .endOf("month")
          .format("DD")
        return setDay(parseInt(lastDay))
      } else {
        dispatch(
          getLDP({
            id: profile?.tenantnum,
            date: selectedDate,
          })
        )
      }
    }
  }, [day, month, year, profile?.tenantnum])

useEffect(() => {
  if(!fetchingDate){
    setMonth(moment(ldpDataDate).month() + 1);
    setDay(moment(ldpDataDate).date());
  }

  return () => {
    dispatch({
      type: SET_YEAR,
      payload: ldpDataDate
    })
  }
}, [ldpDataDate])

  const monthsData = useMemo(() => {
    const { daysInMonth, daysList } = getDaysListInMonth(
      year.getFullYear(),
      month - 1
    )
    return { daysList, daysInMonth }
  }, [month, year])

  const yAxis = useMemo(() => {
   return ldp?.metersData?.[ldp?.meterIds?.[meter-1]]?.filter(Boolean)

  }, [meter, ldp?.metersData]) 
  
  return (
    <ContentContainer
      yearFilter={true}
      meta={{ title: "Load Profile" }}
      info={metaData?.['Understand your Demand Load']}
    >
      <Card>
        <Row>
          <Col xs={12} sm={12} md={12}>
          {ldp?.meterIds &&  <CustomSlider
              data={ldp?.meterIds}
              name="meter"
              setValue={setMeter}
              value={meter}
              min={1}
              max={ldp?.meterIds?.length}
            />}
          </Col>
          <Col xs={12} sm={12} md={12}>
            <CustomSlider
              data={months}
              name="month"
              setValue={setMonth}
              value={month}
              min={1}
              max={12}
            />
          </Col>
          <Col xs={12} sm={12} md={12} className="mt-4">
            <CustomSlider
              data={monthsData?.daysList}
              name="days"
              setValue={setDay}
              value={day}
              min={1}
              max={monthsData?.daysInMonth}
            />
          </Col>
          {loading ? (
            <Col
              xs={12}
              sm={12}
              md={12}
              className="d-flex justify-content-center align-items-center pt-5 mt-5"
            >
              <Loading />
            </Col>
          ) : yAxis?.length <= 0 ? (
            <Col
              xs={12}
              sm={12}
              md={12}
              className="d-flex justify-content-center align-items-center pt-5 mt-5"
            >
              <NotFound />
            </Col>
          ) : (
            <>
              <Col xs={12} sm={12} md={12} className="mt-4 pb-5 mb-5">
                <LineChart
                  data={{
                    xAxis: ldp?.xAxis,
                    yAxis: yAxis,
                  }}
                  yLabel={'KWH'}
                  day={day}
                  month={moment()
                    .month(month - 1)
                    .format("MMMM")}
                  gridLines={true}
                  name="interval"
                  height={350}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
    </ContentContainer>
  )
}
