import MetaTags from 'react-meta-tags';
import BillingComponents from 'components/Billing';

export default function Billing() {
  return (
    <>
      <MetaTags>
        <title>GENERGY | Billing</title>
      </MetaTags>
      <BillingComponents />
    </>
  )
}
