import MetaTags from 'react-meta-tags';
import InsightsComponents from '../../components/Insights';

//Import Action to copy breadcrumb items from local state to redux state
const Insights = () => {

  return (
    <>
      <MetaTags>
        <title>GENERGY | Insights</title>
      </MetaTags>
      <InsightsComponents />

    </>
  )
}

export default Insights;