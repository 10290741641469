import { useState } from "react";
import { ReactComponent as UserIcon } from "../../../assets/images/header/userIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/images/header/logoutIcon.svg";
import { ReactComponent as KeyIcon } from "../../../assets/images/header/keyIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/images/header/profileIcon.svg";
import styles from "./profileMenu.module.scss";
import {
  ProfileModal,
  ResetPasswordModal,
  LogoutModal,
} from "components/Modals";

const ProfileMenu = ({ profile }) => {
  const [menu, setMenu] = useState(false);
  const [profileModal, setprofileModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const profileModalToggle = () => {
    setMenu(!menu);
    setprofileModal(!profileModal)
  };

  const resetPasswordModalToggle = () =>{
    setMenu(!menu);
    setResetPasswordModal(!resetPasswordModal);
  }

  const logoutModalToggle = () => {
    setMenu(!menu);
    setLogoutModal(!logoutModal);
  }

  return (
    <>
      <div className={styles.dropdown}>
        <div className={styles.dropdownToggle} onClick={() => setMenu(!menu)}>
          <UserIcon height={49} width={49} />
        </div>
        <div
          className={`${styles.dropdownMenu} ${
            menu ? styles.show : styles.hide
          }`}
        >
          <div className={`${styles.headerItem} ${styles.menuItem}`}>
            <UserIcon height={25} width={25} />
            <div>
              <p className={styles.fn}>{profile?.firstname}</p>
              <p className={styles.ln}>{profile?.lastname}</p>
            </div>
          </div>
          <div className={styles.centralItemContainer}>
            <div className={`${styles.centralItem} ${styles.menuItem}`} onClick={profileModalToggle}>
              <ProfileIcon height={17} width={17} />
              <p>Profile</p>
            </div>
            <div className={`${styles.centralItem} ${styles.menuItem}`} onClick={resetPasswordModalToggle}>
              <KeyIcon height={17} width={17} />
              <p>Reset Password</p>
            </div>
          </div>
          <div className={`${styles.footerItem} ${styles.menuItem}`} onClick={logoutModalToggle}>
            <LogoutIcon height={17} width={17} />
            <p>Log out</p>
          </div>
        </div>
      </div>
      <ProfileModal modal={profileModal} toggle={profileModalToggle} />
      <ResetPasswordModal
        modal={resetPasswordModal}
        toggle={resetPasswordModalToggle}
      />
      <LogoutModal modal={logoutModal} toggle={logoutModalToggle} />
    </>
  )
}

export default ProfileMenu
