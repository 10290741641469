import styles from "./styles.module.scss"
import { ReactComponent as WarnIcon } from "../../../assets/images/billing/warnIcon.svg"
import InfoBar from "../InfoBar"

export default function CardHeader({ heading, stats, desc, info }) {
  return (
    <div className={styles.container}>
      <div className={styles.headingLeftBox}></div>
      <p className={styles.heading}>{heading}</p>
      {stats && <div className={`${styles.statsBox} center`}>{stats}</div>}
      {desc && (
        <div className={`${styles.descContainer}`}>
          <p>
            <WarnIcon /> Previous bills and payments
          </p>
          <p>
            Please note, debt and credit card payments display in 24-72 hours.
          </p>
        </div>
      )}
      {info && <InfoBar info={info} />}
    </div>
  )
}
