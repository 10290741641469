import { NavLink } from "react-router-dom"
import styles from "./layout.module.scss"
import { ReactComponent as KpiIcon } from "../../assets/images/sidebar/kpi.svg"
import {ReactComponent as IntervalIcon} from "../../assets/images/sidebar/interval.svg"
import {ReactComponent as BillIcon} from "../../assets/images/sidebar/bill.svg"

const navLinks = [
  {
    id: "nav_1",
    name: "Billing",
    icon: <BillIcon />,
    path: "/billing",
  },
  {
    id: "nav_2",
    name: "Insights",
    icon: <KpiIcon />,
    path: "/insights",
  },
  {
    id: "nav_3",
    name: "Load Profile",
    icon: <IntervalIcon />,
    path: "/load-profile",
  },
]

export default function Sidebar() {
  return (
    <div className={styles.sidebar_container}>
      {navLinks.map(el => (
        <div key={el.id} className={styles.nav_item_container}>
          <NavLink
            to={el.path}
            className={isActive => (isActive ? styles.active : styles.inner_container)}
          >
              {el.icon}
              <p>{el.name}</p>
          </NavLink>
        </div>
      ))}
    </div>
  )
}
