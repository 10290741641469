import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Card"
import moment from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Modal, ModalFooter } from "reactstrap"
import { editProfile, editProfileFlag } from "store/actions"

const ProfileModal = ({ modal, toggle }) => {
  const dispatch = useDispatch()
  const { profile, success } = useSelector(state => state.Profile)
  const handleValidSubmit = (event, values) => {
    dispatch(editProfile(profile, values))
  }

  useEffect(() => {
    if (success) {
      toggle()
      dispatch(editProfileFlag(false))
    }
  }, [success])
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <CardComponent
          title="Tenant"
          subtitle="Profile"
          closeButton={modal}
          toggleCloseButton={toggle}
        >
          <AvForm
            className="form-horizontal mt-4 mx-3"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            <div className="mb-3 row text-start">
              <label
                for="example-text-input"
                className="col-md-2 col-form-label"
              >
                First Name
              </label>
              <div className="col-md-10">
                <AvField
                  d="firstname"
                  name="firstname"
                  className="form-control"
                  placeholder="Enter First Name"
                  value={profile?.firstname}
                  required
                />
              </div>
            </div>
            <div className="mb-3 row text-start">
              <label
                for="example-text-input"
                className="col-md-2 col-form-label"
              >
                Last Name
              </label>
              <div className="col-md-10">
                <AvField
                  d="lastname"
                  name="lastname"
                  className="form-control"
                  value={profile?.lastname}
                  placeholder="Enter Last Name"
                  required
                />
              </div>
            </div>

            <div className="mb-3 row text-start">
              <label
                for="example-text-input"
                className="col-md-2 col-form-label"
              >
                Email
              </label>
              <div className="col-md-10">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={`${profile?.email}`}
                />
              </div>
            </div>

            <div className="mb-3 row text-start">
              <label
                for="example-text-input"
                className="col-md-2 col-form-label"
              >
                Sign Up Date
              </label>
              <div className="col-md-10">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={`${moment(profile?.signup_date).format("MM/DD/YYYY")}`}
                />
              </div>
            </div>
            <div className="mb-3 row text-start">
              <label
                for="example-text-input"
                className="col-md-2 col-form-label"
              >
                Tenant Number
              </label>
              <div className="col-md-10">
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={`${profile?.tenantnum || null}`}
                />
              </div>
            </div>
            <ModalFooter>
              <Button color="primary" type="submit" style={{ width: "80px" }}>
                SAVE
              </Button>
              <Button
                color="secondary"
                onClick={toggle}
                style={{ width: "80px" }}
              >
                CANCEL
              </Button>
            </ModalFooter>
          </AvForm>
        </CardComponent>
      </Modal>
    </>
  )
}

export default ProfileModal
