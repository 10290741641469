import PropTypes from "prop-types"
import { useState, useEffect } from "react"

// third-party
import ReactApexChart from "react-apexcharts"

// project import
import { formatCurrency, formatNumber } from "utils/helper"

//styling
import styles from "./styles.module.scss";
import moment from "moment";

// chart options
const areaChartOptions = {
  legend: {
    show: false,
  },
  chart: {
    height: 290,
    type: "bar",
  
    
    toolbar: {
      show: false,
    },
    events: {
      dataPointMouseEnter: function (event) {
        if(event && event.fromElement && event && event.fromElement.style){
          event.fromElement.style.cursor = "pointer"
        }
        event.target.style.cursor = "pointer"
      },
    },
  },
  colors: ["#3668CC"],
  stroke: {
    width: [0],
  },


 
}

const style = {
  colors: "#6F767E",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "12px",
  fontFamily: "Inter",
}

// ==============================|| INCOME AREA CHART ||============================== //

const BarLineChart = ({
  data,
  type,
  plotOptions,
  yLabel,
  rotateX,
  axisTick,
  customYAxis,
  yAxisTickSettings,
  chartHeight=290,
  customUsage,
  decimal=0
}) => {
  const [options, setOptions] = useState(areaChartOptions)
  const [series, setSeries] = useState([])

  useEffect(() => {
    setOptions(prevState => ({
      ...prevState,
      dataLabels: {
        enabled: false,
        position: "top",
        background: {
          enabled: false,
        },
        formatter: function (val) {
          return `${type === "cost" ? "$" : ""}${formatNumber(
            val,
            "en-US",
            2
          )}`
        },
        style: {
          fontSize: "10px",
          fontFamily: "Inter",
          colors: ["#fff"],
        },
      },
      xaxis: {
        categories: data?.xAxis?.map(el => moment(el, 'YYYY-MM-DD').format('MMM')),
        labels: {
          style:{
            ...style
          },
          rotate: rotateX ? rotateX : 0,
          rotateAlways: rotateX && true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: axisTick,
        },
        tickAmount: 12,
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        ...yAxisTickSettings,
        title: {
          text: yLabel,
          style: {...style, color: '#6F767E'},
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style:{
            ...style,
            colors: customYAxis ? '#ffffff' : '#6F767E',
          },
          formatter: function (val) {
            return `${type === "number" ? "" : "$"}${customYAxis ? formatNumber(val, 'en-US', 0 ) : formatNumber(val, 'en-US', decimal) }`
          },
          minWidth: yLabel ? -16 : 0,
          // offsetX: -8
        },
      },
      plotOptions: {
        ...plotOptions,
      },
      grid: {
        borderColor: "#EFEFEF",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: customYAxis,
          },
        },
        padding: {
          bottom: 3,
        },
      },
      fill: {
        type: "solid",
        colors: customUsage ? ["#9cea85"] : ["#3668CC"],
      },
      tooltip: {
        enabled: true,
        intersect: false,
        // shared: true,
        followCursor: false,
        theme: "dark",
    

        custom: function ({ series, dataPointIndex }) {
          const { xAxis } = data
          const value = series?.[0]?.[dataPointIndex]
          const date = xAxis[dataPointIndex]

          return `
             <div class=${styles.tooltipContainer}>
              <div class=${styles.labelContainer}>${moment(date).format('DD MMM YYYY')}</div>
              <div class=${styles.valueContainer}><div class=${customUsage ? styles.legend1 : styles.legend}></div><div class=${styles.value}>${type === 'number' ? formatNumber(value) : formatCurrency(value, 'en-US', 'USD', decimal)}</div></div>
             </div>
            `
        },
      },
    }))
    setSeries([
      {
        type: "bar",
        data: data?.yAxis,
      },
    ])
  }, [data])


  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={chartHeight}
    />
  )
}

BarLineChart.propTypes = {
  color: PropTypes.string,
  yTitle: PropTypes.string,
  gradient: PropTypes.string,
  lineColor: PropTypes.string,
  data: PropTypes.object,
}

export default BarLineChart
