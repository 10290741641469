import React from "react"
import styles from "./yearPicker.module.scss"
import CustomYearPicker from "./yearPicker"

const YearPicker = () => {

  return (
    <div className={styles.container}>
      <CustomYearPicker />
    </div>
  )
}

export default YearPicker
