import { Spinner } from "reactstrap";
import styles from './styles.module.scss';

export default function Loading() {
  return (
    <div
      className={`${styles.container} center`}
    >
      <Spinner />
    </div>
  )
}
