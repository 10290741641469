import axios from 'axios';
import { toast } from "react-toastify"


const BASE_URL = process.env.REACT_APP_BASE_URL1

const axiosServices = axios.create({
baseURL: BASE_URL
});

const requestHandler = (request) => {
  if (!request.url.includes('/authapi/account')) {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    request.headers = headers;

    return request;
  }
  return request;
};

const errorHandler = async (error) => {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token or Expire Token" ||
      errorMessage === "Invalid session" ||
      errorMessage === "Invalid Token" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      if (!toast.isActive("session")) {
        toast.error(errorMessage, {
          autoClose: 3000,
          toastId: "session",
        })
      }
      localStorage.removeItem("authUser")
      localStorage.removeItem("token")
      window.location.href = "/login"
      return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
};

axiosServices.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export default axiosServices;
