import {
    GET_LDP_FAIL,
    GET_LDP_REQUEST,
    GET_LDP_SUCCESS,
    LDP_AVAILABLE_DATA_DATE
} from './actionTypes';

export const getLDP = data => ({
    type: GET_LDP_REQUEST,
    payload: data
  })
  
  export const getLDPSuccess = events => ({
    type: GET_LDP_SUCCESS,
    payload: events,
  })
  
  export const getLDPFail = error => ({
    type: GET_LDP_FAIL,
    payload: error,
  })

  
  export const updateLDPDataDate = (date) => ({
    type: LDP_AVAILABLE_DATA_DATE,
    payload: date
  })