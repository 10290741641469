import { all, call, fork, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { NEW_PASSWORD } from "./actionTypes"
import {
  newPasswordSetFlag,
  userNewPasswordError,
  userNewPasswordSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { toast } from "react-toastify"
import { newPassword } from "../../../helpers/fakebackend_helper"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* userPassword({ payload: { values } }) {
  try {
    const response = yield call(newPassword, values)
    toast.success(response?.message, {
      autoClose: 1000,
    })
    yield put(userNewPasswordSuccess(response))
    yield put(newPasswordSetFlag(true))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"

    console.log("error", error)
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token" ||
      errorMessage === "Invalid session" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      console.log("errorMessage", errorMessage)
      if (!toast.isActive("session")) {
        toast.error(errorMessage, {
          autoClose: 3000,
          toastId: "session",
        })
      }
      localStorage.removeItem("authUser")
      localStorage.removeItem("token")
      window.location.href = "/login"
      yield put(userNewPasswordError(error))
      return
    }
    toast.error(errorMessage, {
      autoClose: 3000,
    })
    yield put(userNewPasswordError(error))
  }
}

export function* watchUserPasswordNew() {
  yield takeEvery(NEW_PASSWORD, userPassword)
}

function* newPasswordSaga() {
  yield all([fork(watchUserPasswordNew)])
}

export default newPasswordSaga
