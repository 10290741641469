import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

//Include Both Helper File with needed methods
import { toast } from "react-toastify"
import { login, logout } from "../../../helpers/fakebackend_helper"
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"

// const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, user)
    localStorage.setItem("authUser", JSON.stringify(response?.data))
    localStorage.setItem("token", response?.data?.token)
    yield put(loginSuccess(response))
    history.push("/billing")
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Something went wrong"
    console.log("error", error)
    toast.error(errorMessage, {
      autoClose: 3000,
    })
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { user } }) {
  try {
    const response = yield call(logout, user)
    localStorage.removeItem("authUser")
    localStorage.removeItem("token")
    toast.success(response.message, {
      autoClose: 1000,
    })
    window.location.href = "/login"
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }
    if (
      errorMessage === "Invalid Token or Expire Token" ||
      errorMessage === "Invalid session" ||
      errorMessage ===
        "Session has expired due to inactivity. Please login again."
    ) {
      console.log("errorMessage", errorMessage)
      if (!toast.isActive("session")) {
        toast.error(errorMessage, {
          autoClose: 3000,
          toastId: "session",
        })
      }
      localStorage.removeItem("authUser")
      localStorage.removeItem("token")
      window.location.href = "/login"
      yield put(apiError(error))
      return
    }
    toast(errorMessage)
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
