import { call, put, takeEvery } from "redux-saga/effects"
import { updateOccupancyFail, updateOccupancySuccess } from "./actions"
import { toast } from "react-toastify"
import axiosServices from "helpers/axios"
import _ from 'lodash';
import { updateOccupancyCost } from "../Insights/actions";

const { UPDATE_OCCUPANCY_REQUEST } = require("./actionTypes")

const callKPI = async ({ tenantnum, occupancy }) =>
  await axiosServices.post(
    `${process.env.REACT_APP_OCCUPANTS_URL}/kpi/occupants/update/${tenantnum}`, {occupancy}
  )

function* updateOccupancy({ payload: data }) {
  try {
    const occupancy = parseInt(data?.occupancy) || 0
    const res = yield call(callKPI, data)
    yield put(updateOccupancyCost(occupancy))
    yield put(updateOccupancySuccess(occupancy))
    toast.success(res?.data?.message, {
        autoClose: 1000,
      })
  } catch (error) {
    let errorMessage =
      error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }

    toast.error(errorMessage, {
      autoClose: 3000,
    })
    yield put(updateOccupancyFail(error?.response))
  }
}

function* filtersSaga() {
  yield takeEvery(UPDATE_OCCUPANCY_REQUEST, updateOccupancy)
}

export default filtersSaga
