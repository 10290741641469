import { call, put, takeEvery } from "redux-saga/effects"
import { getMetaDataFail, getMetaDataSuccess } from "./actions"
import { toast } from "react-toastify"
import axiosServices from "helpers/axios"
import moment from "moment"

const { GET_META_DATA_REQUEST } = require("./actionTypes")

const callMetaData = async () =>
  await axiosServices.get(
    `${process.env.REACT_APP_TOOLTIP_URL}`
  )

function* fetchMetaData() {
  try {
  
    const response = yield call(callMetaData);
    const data = response?.data?.data

  let normalizedData= {};
    
  data.forEach(el => {
    normalizedData[el?.tipkey]= {
      title: el?.tipkey,
      description: el?.tooltip?.replace(/\n/g, '<br/>'),
      pageref: el?.pageref,
    }
  });

   
    yield put(getMetaDataSuccess(normalizedData))
  } catch (error) {
    let errorMessage =
      error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }

    toast.error(errorMessage, {
      autoClose: 3000,
    })
    yield put(getMetaDataFail(error?.response))
  }
}

function* metaDataSaga() {
  yield takeEvery(GET_META_DATA_REQUEST, fetchMetaData)
}

export default metaDataSaga
