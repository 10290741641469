import {
  GET_META_DATA_REQUEST,
  GET_META_DATA_SUCCESS,
  GET_META_DATA_FAIL
} from "./actionTypes"

const initialStage = {
  metaData: {},
  loading: false,
  error: "",
}

const MetaData = (state = initialStage, action) => {
  switch (action.type) {
    case GET_META_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      }

    case GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: action.payload,
        loading: false,
      }

    case GET_META_DATA_FAIL:
      return {
        ...state,
        metaData: {},
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default MetaData;
