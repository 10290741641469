import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Register from "./auth/register/reducer"
import NewPassword from "./auth/newpwd/reducer"

//Billing
import Billing from "./billing/reducer";

//LDP
import LDP from "./LDP/reducer"

//Insights
import Insights from "./Insights/reducer"

// Filters
import Filters from './filters/reducer';

// Meta Data
import MetaData from './metaData/reducer';


const rootReducer = combineReducers({
  // public
  Layout,
  //Breadcrumb items
  Breadcrumb,
  NewPassword,

  Login,
  Register,
  Account,
  ForgetPassword,
  Profile,
  Billing,
  LDP,
  Insights,
  Filters,
  MetaData
})

export default rootReducer
