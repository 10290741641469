import styles from "./styles.module.scss"

export default function InfoKeyValue({title, value}) {
    return (
        <div className={styles.container}>
            <p className={styles.title}>{title}</p>
            <p className={styles.value}>{value}</p>
        </div>
    )

}