import React, { useState } from "react"
import { Col, Offcanvas, Row } from "reactstrap"
import { mdiCloseThick, mdiInformationOutline } from "@mdi/js"
import Icon from "@mdi/react"
import styles from "./styles.module.scss"

function InfoBar({ info }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Icon path={mdiInformationOutline} className={styles.infoBtn} onClick={toggle} />
      <Offcanvas isOpen={isOpen} toggle={toggle} direction="end">
        <div className={styles.container}>
          <Row>
            <Col xs={12} className={styles.closeIcon}>
              <Icon
                path={mdiCloseThick}
                height={25}
                width={25}
                onClick={toggle}
              />
            </Col>
            <Col xs={12} className="mt-0">
              <p className={styles.heading}>{info?.title}</p>
            </Col>
            <Col xs={12}>
              <div className={styles.description}>
                <p dangerouslySetInnerHTML={{__html: `<p>${info?.description}</p>`}}></p>
              </div>
            </Col>
          </Row>
        </div>
      </Offcanvas>
    </>
  )
}

export default InfoBar
