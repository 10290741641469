import { InvoiceModal } from "components/Modals"
// import PaginationComponent from "components/Pagination"
import moment from "moment"
import { useEffect, useState } from "react"
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "reactstrap"
import { singleEventDetails } from "store/actions"
import "./styles.scss"
import NotFound from "components/Common/NotFound"
import Loading from "components/Common/Loading"
import _ from "lodash"

function TableComponent() {
  const { events, eventLoading } = useSelector(state => state.Billing)
  const dispatch = useDispatch()

  const [invoiceModal, setInvoiceModal] = useState(false)

  const toggleInvoiceModal = () => setInvoiceModal(!invoiceModal)
  const [modalData, setModalData] = useState({})
  // const [currentPage, setCurrentPage] = useState(1)
  // const [pagesCount, setPagesCount] = useState(1)
  const [visibleEvents, setVisibleEvents] = useState([])
  const [sort, setSort] = useState("")

  // const handlePageChange = pageNumber => {
  //   setCurrentPage(pageNumber)
  // }

  // useEffect(() => {
  //   if (events?.length) {
  //     const sortedEvents = [...events].sort((a, b) =>
  //       moment(b.datestart).diff(moment(a.datestart))
  //     )
  //     setPagesCount(getPagesCount(sortedEvents.length, 5))
  //     setVisibleEvents(sortedEvents)
  //   } else {
  //     setPagesCount(1)
  //     setVisibleEvents([])
  //   }
  // }, [events])

  // const getPagesCount = (totalItems, itemsPerPage) => {
  //   return Math.ceil(totalItems / itemsPerPage)
  // }

  useEffect(() => {
    // setPagesCount(getPagesCount(events.length, 5))
    // const startIndex = (currentPage - 1) * 5
    // const endIndex = startIndex + 5

    if (events?.length) {
      const sortedEvents = [...events].sort((a, b) =>
        moment(b.datestart).diff(moment(a.datestart))
      )
      // setVisibleEvents(sortedEvents.slice(startIndex, endIndex))
      setVisibleEvents(sortedEvents)
    } else {
      setVisibleEvents([])
    }
  }, [events])

  const handleSort = (sortLabel, type) => {
    if (type === "asc") {
      setSort(sortLabel)
    } else if (type === "desc") {
      setSort("")
    }
    const sortedEvents = _.orderBy(events, [sortLabel], [type])
    setVisibleEvents(sortedEvents)
  }

  return (
    <div>
      <Table
        responsive
        borderless
        id="tech-companies-1"
        className="text-center table  billing-table"
      >
        <thead>
          <tr>
            <th className="text-start">
              Date{" "}
              {sort === "postdate" ? (
                <AiFillCaretUp onClick={() => handleSort("postdate", "desc")} />
              ) : (
                <AiFillCaretDown
                  onClick={() => handleSort("postdate", "asc")}
                />
              )}
            </th>
            <th className="text-start">Description</th>
            <th>
              KW{" "}
              {sort === "demand" ? (
                <AiFillCaretUp onClick={() => handleSort("demand", "desc")} />
              ) : (
                <AiFillCaretDown onClick={() => handleSort("demand", "asc")} />
              )}
            </th>
            <th>
              KWH{" "}
              {sort === "energy" ? (
                <AiFillCaretUp onClick={() => handleSort("energy", "desc")} />
              ) : (
                <AiFillCaretDown onClick={() => handleSort("energy", "asc")} />
              )}
            </th>
            <th>Action</th>
          </tr>
        </thead>
      </Table>

      <div className="tableContainer">
       {visibleEvents.length > 0 && <Table
          // striped
          responsive
          borderless
          id="tech-companies-1"
          className="text-center table  billing-table"
        >
          <tbody style={{ verticalAlign: "middle" }}>
            {visibleEvents.length > 0 &&
              visibleEvents.map((item, index) => (
                <tr key={index++}>
                  <td className="text-start">
                    {moment(item.postdate).format("MM/DD/YYYY")}
                  </td>
                  <td className="text-start no-wrap">
                    REGULAR BILL
                      {" "}
                      for {moment(item.datestart).format("MM/DD/YYYY")} -{" "}
                      {moment(item.dateend).format("MM/DD/YYYY")}
                  </td>
                  <td>{item?.demand ? item?.demand : 0}</td>
                  <td>{item?.energy ? item.energy : 0}</td>
                  <td onClick={() => {
                        dispatch(singleEventDetails(item))
                        toggleInvoiceModal()
                        setModalData(item)
                      }}>
                   
                      Download INV
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>}
        {!eventLoading && visibleEvents.length <= 0 && <NotFound />}
        {eventLoading && <Loading />}

        {/* <div className="mt-1">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={pagesCount || 1} // Replace with the total number of pages you have
          onPageChange={handlePageChange}
        />
      </div> */}
        <InvoiceModal
          modal={invoiceModal}
          toggle={toggleInvoiceModal}
          invoiceData={modalData}
        />
      </div>
    </div>
  )
}

export default TableComponent
