import { Col, Row } from "reactstrap"
import BarChart from "components/Common/barChart"
import CardHeader from "components/Common/CardHeader"
import ContentContainer from "components/Common/ContentContainer"
import Card from "components/Common/Card"
import LineChart from "components/Common/lineChart"
import StatsCard from "components/Common/StatsCard"
import { ReactComponent as DollarCircle } from "../../assets/images/insights/circleDollar.svg"
import { ReactComponent as BarIcon } from "../../assets/images/insights/barIcon.svg"
import { ReactComponent as ColIcon } from "../../assets/images/insights/colIcon.svg"
import { ReactComponent as EmissionIcon } from "../../assets/images/insights/emissionIcon.svg"
import { RESET_DATE } from "store/filters/actionTypes"
import { useDispatch, useSelector } from "react-redux"
import { getInsightsFail, getKPI } from "store/actions"
import { useEffect, useRef } from "react"
import moment from "moment"
import {
  dateFormat,
  formatCurrency,
  formatNumber,
  validateDateRange,
} from "utils/helper"
import NotFound from "components/Common/NotFound"
import Loading from "components/Common/Loading"
import { toast } from "react-toastify"

const Insights = () => {
  const dispatch = useDispatch()
  const isFirstRender = useRef(true)

  const { profile } = useSelector(state => state.Profile)
  const { metaData } = useSelector(state => state.MetaData)
  const { startDate, endDate } = useSelector(state => state.Filters)

  const {
    xAxis,
    loading,
    totalKWH,
    peakDemand,
    totalCost,
    sumTotalKWH,
    demandPeakValue,
    sumTotalCost,
    costKWH,
    avgCostKWH,
    costOccupant,
    emissionOccupant,
    perOccupant,
    loadFactor,
    kwhSqFt,
    wattsSqFt,
    costSqFt,
    emission,
  } = useSelector(state => state.Insights)

  const fetchData = () => {
    const dates = {
      page: "insights",
      tenantnum: profile?.tenantnum,
      startDate: moment(startDate, "YYYY-MM-DD")
        .startOf("month")
        ?.format("YYYY-MM-DD"),
      endDate: moment(endDate, "YYYY-MM-DD")
        .endOf("month")
        ?.format("YYYY-MM-DD"),
    }

    if (profile?.tenantnum) {
      const message = validateDateRange(startDate, endDate)
      if (message && !isFirstRender.current) {
        toast.error(message, {
          autoClose: 3000,
        })
        return dispatch(getInsightsFail())
      } else if (!message) {
        isFirstRender.current ? (isFirstRender.current = false) : ""
        dispatch(getKPI(dates))
      }
    }
  }

  useEffect(() => {
    return () => {
      if (profile?.tenantnum) {
        isFirstRender.current = true
        dispatch({
          type: RESET_DATE,
          payload: "",
        })
      }
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [dateFormat(startDate), dateFormat(endDate), profile?.tenantnum])

  return (
    <ContentContainer
      rangeFilter={true}
      occupancyFilter={true}
      meta={{ title: "Efficiency Insights" }}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
          <Row className={'gapSm'} >
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <StatsCard
                title={"Cost Per Sqft"}
                stats={costSqFt}
                icon={<DollarCircle />}
                info={metaData?.["What is Cost per SQFT?"]}
                />
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <StatsCard
                title={"Cost Per Occupant"}
                stats={costOccupant}
                icon={<DollarCircle />}
                info={metaData?.["What is Cost per Occupant?"]}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <StatsCard
                title={"Average Load Factor"}
                stats={loadFactor}
                icon={<ColIcon />}
                info={metaData?.["What is Average Load Factor?"]}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <StatsCard
                title={"WATTS Per Sqft"}
                stats={wattsSqFt}
                icon={<ColIcon />}
                info={metaData?.["What is Watts per Sqft?"]}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={6}
        >
          <Row className={'gapSm'}>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <StatsCard
                title={"KWH Per Sqft"}
                stats={kwhSqFt}
                icon={<BarIcon />}
                info={metaData?.["What is KWH per Sqft?"]}
                />
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <StatsCard
                title={"KWH Per Occupant"}
                stats={perOccupant}
                icon={<BarIcon />}
                info={metaData?.["What is KWH per Occupant?"]}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <StatsCard
                title={"Total Emissions (kt)"}
                stats={emission}
                icon={<EmissionIcon />}
                info={metaData?.["Undestand your Total Emissions"]}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
            <StatsCard
              title={`CO² Per Occupant (kt)`}
              stats={emissionOccupant}
              icon={<EmissionIcon />}
              info={metaData?.['What is CO2 per Occupant?']}
            />
            </Col>
          </Row>

        </Col>
      </Row>
      <Row className="pt-3">
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader
              heading={"Total KWH"}
              stats={formatNumber(sumTotalKWH)}
              info={metaData?.["What is Total KWH?"]}
            />
            {loading ? (
              <Loading />
            ) : totalKWH?.length > 0 ? (
              <LineChart
                data={{
                  xAxis: xAxis || [],
                  yAxis: totalKWH,
                }}
                text="Total Cost"
                type="cost"
                utility="electric"
                axisTick={true}
                plotOptions={{
                  bar: {
                    columnWidth: "25px",
                    borderRadius: 12,
                    endingShape: "rounded",
                    borderRadiusApplication: "end",
                  },
                }}
              />
            ) : (
              <NotFound />
            )}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader
              heading={"Peak Demand"}
              stats={formatNumber(demandPeakValue)}
              info={metaData?.["Understand your Peak Demand"]}
            />
            {loading ? (
              <Loading />
            ) : peakDemand?.length > 0 ? (
              <BarChart
                data={{
                  xAxis: xAxis || [],
                  yAxis: peakDemand,
                }}
                text="Total Cost"
                type="number"
                utility="electric"
                axisTick={true}
                plotOptions={{
                  bar: {
                    columnWidth: "25px",
                    borderRadius: 12,
                    endingShape: "rounded",
                    borderRadiusApplication: "end",
                  },
                }}
              />
            ) : (
              <NotFound />
            )}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader
              heading={"Total Electric Cost"}
              stats={formatCurrency(sumTotalCost)}
              info={metaData?.["What is Total Electric Cost?"]}
            />
            {loading ? (
              <Loading />
            ) : totalCost?.length > 0 ? (
              <BarChart
                data={{
                  xAxis: xAxis || [],
                  yAxis: totalCost,
                }}
                text="Total Cost"
                type="cost"
                utility="electric"
                axisTick={true}
                plotOptions={{
                  bar: {
                    columnWidth: "25px",
                    borderRadius: 12,
                    endingShape: "rounded",
                    borderRadiusApplication: "end",
                  },
                }}
              />
            ) : (
              <NotFound />
            )}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader
              heading={"Cost Per KWH"}
              stats={formatCurrency(avgCostKWH)}
              info={metaData?.["What is Cost per KWH?"]}
            />
            {loading ? (
              <Loading />
            ) : costKWH?.length > 0 ? (
              <BarChart
                data={{
                  xAxis: xAxis || [],
                  yAxis: costKWH,
                }}
                text="Total Cost"
                type="cost"
                utility="electric"
                axisTick={true}
                decimal={3}
                plotOptions={{
                  bar: {
                    columnWidth: "25px",
                    borderRadius: 12,
                    endingShape: "rounded",
                    borderRadiusApplication: "end",
                  },
                }}
              />
            ) : (
              <NotFound />
            )}
          </Card>
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default Insights
