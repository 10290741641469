import styles from "./styles.module.scss"
import { ReactComponent as PlusIcon } from "../../../assets/images/header/plusCircle.svg"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_OCCUPANCY } from "store/filters/actionTypes";
import { updateOccupancy } from "store/filters/actions";
import { Spinner } from "reactstrap";

export default function OccupancyDropDown() {
  const dispatch = useDispatch();
  const {occupancy, loading} = useSelector(state => state?.Filters);
  const { profile } = useSelector(state => state.Profile);
  const [active, setActive] = useState(false)
  const [value, setValue] = useState(occupancy || 1)
  
  const handleOpen = () => {
    setActive(!active)
    setValue(occupancy);
  }

  const handleSubmit = () => {
    dispatch(updateOccupancy({tenantnum: profile?.tenantnum, occupancy: value}))
    setActive(!active)
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.dropBtn} ${active && styles.active}`}
        onClick={handleOpen}
      >
        <div className={`${styles.occNumb} center`}>{loading ? <Spinner size={'sm'} /> : occupancy}</div>
        <p>Occupancy</p>
        <PlusIcon />
      </div>
      {active && (
        <div className={styles.dropdown}>
          <input
            onChange={e => setValue(e.target.value > 0 ? e.target.value : 1)}
            type="number"
            min={1}
            value={value}
          />
          <button disabled={loading} type="submit" onClick={handleSubmit}>
          {loading ? <Spinner size={'sm'} /> : 'Save Update'}
          </button>
        </div>
      )}
    </div>
  )
}
