import {
  EDIT_PROFILE,
  EDIT_PROFILE_FLAG,
  GET_PROFILE,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

export const getProfileDetails = user => {
  return {
    type: GET_PROFILE,
    payload: { user },
  }
}

export const editProfile = (user, values) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, values },
  }
}

export const profileSuccess = user => {
  return {
    type: PROFILE_SUCCESS,
    payload: { user },
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const editProfileFlag = flag => {
  return {
    type: EDIT_PROFILE_FLAG,
    payload: { flag },
  }
}
