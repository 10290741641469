import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import { apiError, registerUser, registerUserFailed } from "../../store/actions"

//css
import "../../App.css"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

// import images
import { ImEye, ImEyeBlocked } from "react-icons/im"
import cplLogo from "../../assets/images/cpl-logo-removebg.jpg"

const Register = props => {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state?.Register)

  const [isShow, setIsShow] = useState(false)

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    // props.registerUser(values)
    dispatch(registerUser(values, props?.history))
  }

  useEffect(() => {
    props.apiError("")
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | GENERGY</title>
      </MetaTags>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mb-2">
                <CardBody className="pt-0 px-0">
                  <div className=" py-1" style={{ backgroundColor: "#3668CC" }}>
                    <h4
                      className="pt-1 text-white font-size-15 font-italic mb-0 text-center fw-normal !important"
                      style={{ fontStyle: "italic" }}
                    >
                      Tenant
                    </h4>
                    <h2 className="text-white text-center mb-0 fw-bold">
                      REGISTRATION
                    </h2>
                  </div>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-0 text-center fw-bold">
                      HELLO !
                    </h4>
                    <p className="text-muted text-center mt-0">
                      Please fill in the fields below to continue
                    </p>
                    <AvForm
                      className="form-horizontal mt-4 mx-3"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {/* {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null} */}

                      {/* {props.registrationError && props.registrationError ? (
                        <Alert color="danger">{props.registrationError}</Alert>
                      ) : null} */}

                      {/* <div className="mb-3">
                        <AvField
                          id="firstname"
                          name="firstname"
                          label="First Name"
                          className="form-control"
                          placeholder="Enter First Name"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          id="lastname"
                          name="lastname"
                          label="Last Name"
                          className="form-control"
                          placeholder="Enter Last Name"
                          type="text"
                          required
                        />
                      </div> */}
                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          id="tenantId"
                          name="tenantId"
                          label="Tenant ID"
                          placeholder="Enter Tenant ID"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <AvField
                          name="password"
                          label="Password"
                          type={isShow ? "text" : "password"}
                          required
                          placeholder="Enter Password"
                        />
                        <span
                          className={`fs-3 position-absolute`}
                          style={{
                            top: "27px",
                            right: "10px",
                            cursor: "pointer",
                            opacity: "0.3",
                          }}
                          onClick={() => setIsShow(!isShow)}
                        >
                          {isShow ? <ImEye /> : <ImEyeBlocked />}
                        </span>
                      </div>

                      <div className=" mt-5 px-5 d-flex justify-content-center">
                        <button
                          className="btn btn-primary waves-effect waves-light btn-lg rounded-pill w-100 mx-auto max-width-250"
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            height: "50px",
                            maxWidth: "300px",
                          }}
                          type="submit"
                        >
                          {loading ? (
                            <Spinner size="sm" type="border" color="light" />
                          ) : (
                            "REGISTER"
                          )}
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center">
                <p className="text-muted mb-0 font-size-14">
                  By registering you agree to the{" "}
                  <Link
                    to="/terms-of-use"
                    target="_blank"
                    className="text-primary"
                  >
                    Terms of Use
                  </Link>
                </p>
              </div>
              <div className="text-center mt-2">
                <img src={cplLogo} height={40}  width={'auto'} />
              </div>
            </Col>
          </Row>
          {loading && (
            <div className="overlay">
              <Spinner type="grow" color="warning" />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
