import {
    GET_META_DATA_FAIL,
    GET_META_DATA_REQUEST,
    GET_META_DATA_SUCCESS,
} from './actionTypes';

export const getMetaData = data => ({
    type: GET_META_DATA_REQUEST,
    payload: data
  })
  
  export const getMetaDataSuccess = events => ({
    type: GET_META_DATA_SUCCESS,
    payload: events,
  })
  
  export const getMetaDataFail = error => ({
    type: GET_META_DATA_FAIL,
    payload: error,
  })

