import {
  AvField,
  AvForm,
  AvFeedback,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"
import CardComponent from "components/Card"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Modal, ModalFooter } from "reactstrap"
import { newPasswordSetFlag, userNewPassword } from "../../store/actions"
import { ImEye, ImEyeBlocked } from "react-icons/im"

const ResetPasswordModal = ({ modal, toggle }) => {
  const dispatch = useDispatch()
  const { setFlag } = useSelector(state => state.NewPassword)
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false)
  const [isPasswordShow, setIsPasswordShow] = useState(false)
  const [isOldPasswordShow, setIsOldPasswordShow] = useState(false)

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    // props.registerUser(values)
    const user = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      email: user.email,
    }
    dispatch(userNewPassword(data))
  }

  useEffect(() => {
    if (setFlag) {
      toggle()
      dispatch(newPasswordSetFlag(false))
    }
  }, [setFlag])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <CardComponent
          title="Tenant"
          subtitle="Reset Password"
          closeButton={modal}
          toggleCloseButton={toggle}
        >
          <AvForm
            className="form-horizontal mt-4 mx-3"
            onValidSubmit={handleValidSubmit}
          >
            <AvGroup className="mb-3 row text-start">
              <label className="col-md-3 col-form-label">Old Password</label>
              <div className="col-md-9">
                <AvField
                  d="oldPassword"
                  name="oldPassword"
                  type={isOldPasswordShow ? "text" : "password"}
                  placeholder="Enter Old Password"
                  required
                />
                <span
                  className="fs-3 position-absolute"
                  style={{
                    top: "0px",
                    right: "20px",
                    cursor: "pointer",
                    opacity: "0.3",
                  }}
                  onClick={() => setIsOldPasswordShow(!isOldPasswordShow)}
                >
                  {isOldPasswordShow ? <ImEye /> : <ImEyeBlocked />}
                </span>
              </div>
            </AvGroup>
            <AvGroup className="mb-3 row text-start">
              <label className="col-md-3 col-form-label">New Password</label>
              <div className="col-md-9">
                <AvField
                  d="newPassword"
                  name="newPassword"
                  className="form-control"
                  placeholder="Enter New Password"
                  type={isPasswordShow ? "text" : "password"}
                  required
                />
                <span
                  className="fs-3 position-absolute"
                  style={{
                    top: "0px",
                    right: "20px",
                    cursor: "pointer",
                    opacity: "0.3",
                  }}
                  onClick={() => setIsPasswordShow(!isPasswordShow)}
                >
                  {isPasswordShow ? <ImEye /> : <ImEyeBlocked />}
                </span>
              </div>
            </AvGroup>
            <AvGroup className="mb-3 row text-start">
              <label className="col-md-3 col-form-label">
                Confirm Password
              </label>
              <div className="col-md-9">
                <AvField
                  d="newPassword"
                  name="confirmPassword"
                  type={isConfirmPasswordShow ? "text" : "password"}
                  placeholder="Enter Confirm Password"
                  required
                  validate={{
                    match: {
                      value: "newPassword",
                      errorMessage:
                        "The new password and confirmation password do not match.",
                    },
                  }}
                />
                <span
                  className="fs-3 position-absolute"
                  style={{
                    top: "0px",
                    right: "20px",
                    cursor: "pointer",
                    opacity: "0.3",
                  }}
                  onClick={() =>
                    setIsConfirmPasswordShow(!isConfirmPasswordShow)
                  }
                >
                  {isConfirmPasswordShow ? <ImEye /> : <ImEyeBlocked />}
                </span>
              </div>
            </AvGroup>
            <ModalFooter>
              <Button color="primary" type="submit" style={{ width: "137px" }}>
                RESET PASSWORD
              </Button>
              <Button
                color="secondary"
                onClick={toggle}
                style={{ width: "137px" }}
              >
                CANCEL
              </Button>
            </ModalFooter>
          </AvForm>
        </CardComponent>
      </Modal>
    </>
  )
}

export default ResetPasswordModal
