import {
   GET_KPI_REQUEST,
   GET_INSIGHTS_SUCCESS,
   GET_INSIGHTS_FAIL,
   GET_BILLING_KPI_SUCCESS,
   GET_BILLING_KPI_FAIL,
   UPDATE_OCCUPANCY_COST
} from './actionTypes';

export const getKPI = data => ({
    type: GET_KPI_REQUEST,
    payload: data
  })
  
  export const getBillingKPISuccess = events => ({
    type: GET_BILLING_KPI_SUCCESS,
    payload: events,
  })
  
  export const getBillingKPIFail = error => ({
    type: GET_BILLING_KPI_FAIL,
    payload: error,
  })

  export const getInsightsSuccess = events => ({
    type: GET_INSIGHTS_SUCCESS,
    payload: events,
  })
  
  export const getInsightsFail = error => ({
    type: GET_INSIGHTS_FAIL,
    payload: error,
  })
  
  export const updateOccupancyCost = occupancy => ({
    type: UPDATE_OCCUPANCY_COST,
    payload: occupancy,
  })
