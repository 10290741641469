import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import "../../App.css"

import { Alert, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import { apiError, loginUser } from "../../store/actions"

// import images
import { ImEye, ImEyeBlocked } from "react-icons/im"
import cplLogo from "../../assets/images/cpl-logo-removebg.jpg"

const Login = props => {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state?.Login)

  const [isShow, setIsShow] = useState(false)

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props?.history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | GENERGY</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0 px-0">
                  <div className=" py-1" style={{ backgroundColor: "#3668CC" }}>
                    <h4
                      className="pt-1 text-white font-size-15 font-italic mb-0 text-center fw-normal !important"
                      style={{ fontStyle: "italic" }}
                    >
                      Tenant
                    </h4>
                    <h2 className="text-white text-center mb-0 fw-bold">
                      LOGIN
                    </h2>
                  </div>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-0 text-center">
                      WELCOME BACK !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue
                    </p>
                    <AvForm
                      className="form-horizontal mt-4 mx-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props?.error && typeof props?.error === "string" ? (
                        <Alert color="danger">{props?.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email address"
                          className="form-control text-muted !important"
                          placeholder="Enter Email address"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <AvField
                          name="password"
                          label="Password"
                          type={isShow ? "text" : "password"}
                          required
                          placeholder="Enter Password"
                        />
                        <span
                          className={`fs-3 position-absolute`}
                          style={{
                            top: "27px",
                            right: "10px",
                            cursor: "pointer",
                            opacity: "0.3",
                          }}
                          onClick={() => setIsShow(!isShow)}
                        >
                          {isShow ? <ImEye /> : <ImEyeBlocked />}
                        </span>
                      </div>

                      {/* <div className="mb-3">
                        <AvField
                          type="select"
                          name="userType"
                          label="Account Type"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please select an account type",
                            },
                          }}
                        >
                          <option>Please select account type</option>
                          <option value={"tenant"}>Tenant</option>
                          <option value={"client"}>Client</option>
                        </AvField>
                      </div> */}

                      <div className="mb-3 row mt-2">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" mt-4 px-5 d-flex justify-content-center">
                        <button
                          className="btn btn-primary waves-effect waves-light btn-lg rounded-pill w-100 mx-auto max-width-250"
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            height: "50px",
                            maxWidth: "300px",
                          }}
                          type="submit"
                        >
                          {loading ? (
                            <Spinner size="sm" type="border" color="light" />
                          ) : (
                            "LOGIN"
                          )}
                        </button>
                      </div>

                      <div className="form-group mb-0 mt-4 row justify-content-center align-items-center">
                        <div className="col-auto">
                          <Link
                            to="/forgot-password"
                            className="text-primary fw-bold small"
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className="col-auto">
                          <span className="text-primary fw-bold small">|</span>
                        </div>
                        <div className="col-auto">
                          <Link
                            to="register"
                            className="text-primary fw-bold small"
                          >
                            Register Now
                          </Link>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center mt-0">
                <img src={cplLogo} height={40}  width={'auto'} />
              </div>
            </Col>
          </Row>
          {loading && (
            <div className="overlay">
              <Spinner type="grow" color="warning" />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
