import {
  NEW_PASSWORD,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_SET_FLAG,
  NEW_PASSWORD_SUCCESS,
} from "./actionTypes"

export const userNewPassword = values => {
  return {
    type: NEW_PASSWORD,
    payload: { values },
  }
}

export const userNewPasswordSuccess = message => {
  return {
    type: NEW_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userNewPasswordError = message => {
  return {
    type: NEW_PASSWORD_ERROR,
    payload: message,
  }
}

export const newPasswordSetFlag = flag => {
  return {
    type: NEW_PASSWORD_SET_FLAG,
    payload: { flag },
  }
}
