import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ConfirmPassword from "../pages/Authentication/ConfirmPassword"

// Secure routes
import Billing from "../pages/Billing"
import LoadProfile from "../pages/LoadProfile"
import Insights from "../pages/Insights"
import NewPassword from "pages/Authentication/NewPassword"
import TermsOfUse from "pages/Authentication/TermsOfUse"

const userRoutes = [
  { path: "/billing", component: Billing },
  { path: "/insights", component: Insights },
  { path: "/load-profile", component: LoadProfile },

  // // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/billing" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/confirm-password", component: ConfirmPassword },
  { path: "/new-password", component: NewPassword },
  { path: "/terms-of-use", component: TermsOfUse },
]

export { userRoutes, authRoutes }
